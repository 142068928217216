import { Backdrop, Button, Typography, CircularProgress } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { COLLECTION_NAMES } from "config/misc";

const ApprovalDialog = function ({ onClose, onConfirm, isConfirming = false, registry }: any) {
  const theme = useTheme();

  return (
    <Backdrop open sx={{ backdropFilter: "blur(6px)", zIndex: 10000 }}>
      <Box
        textAlign="center"
        sx={{
          padding: "40px",
          borderRadius: "20px",
          maxWidth: "720px",
          backgroundColor: "#161616",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "18px", marginBottom: "24px", color: theme.palette.text.primary }}>
          Approve your wallet for trades in the {COLLECTION_NAMES[registry]}s collection
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "18px",
            marginBottom: "40px",
            color: theme.palette.text.primary,
          }}
        >
          To continue, you must approve your wallet for trades within the Regulars collection. You will only need to
          approve this transaction once.
        </Typography>
        <Box gap="10px" display="flex" justifyContent="center">
          <Box sx={{ position: "relative", width: "100%" }}>
            <Button variant="cancel" onClick={onClose} style={{ marginRight: "30px" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onConfirm}
              disabled={isConfirming}
              sx={{ display: "inline-flex", position: "relative" }}
            >
              {isConfirming && (
                <CircularProgress
                  color="success"
                  size={24}
                  sx={{
                    "& svg": {
                      marginRight: 0,
                      marginLeft: 0,
                    },
                    top: "5px",
                    left: "0",
                    position: "relative",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    marginRight: "12px",
                  }}
                />
              )}
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default ApprovalDialog;
