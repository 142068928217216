import { styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

const GrayTextInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: "#E0E0E0",
  color: "black",
  borderColor: "#E0E0E0",
  borderRadius: "12px",
  "& fieldset": {
    borderRadius: "12px",
  },
  "&.Mui-focused": {
    backgroundColor: "#F2F2F2",
    borderColor: "#E0E0E0",
  },
}));

export default GrayTextInput;
