import * as React from "react";
import { ethers } from "ethers";
import { Box, Button, Typography, IconButton, useMediaQuery, Container, Stack, CircularProgress } from "@mui/material";

import { useTheme } from "@mui/system";
import TextField from "@mui/material/TextField";
import NFTCard from "components/NFTCard";
import { useNavigate } from "react-router-dom";
import { useActiveWeb3React } from "hooks/web3";
import { UGLYPOOL_CONTRACT_ADDRESS, DAPP_ID } from "config/misc";
import { DEFAULT_CHAIN_ID } from "config/chains";
import abiUglyPool from "abi/uglypool.json";
import { allNFTsByWallet, setApproval } from "utils/helper";
import Notify from "bnc-notify";
import * as fb from "lib/firebase";
import { isAddress } from "ethers/lib/utils";
import NFTCollectionInfoBox from "components/NFTCollectionInfoBox";
import CreatPoolStepper from "components/CreatePoolStepper";
import { formatAddress } from "utils";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PoolDetailInfoBox from "components/PoolDetailInfoBox";
import Dialog from "components/Dialog";
import GrayTextInput from "components/GrayTextInput";
import { icon_close } from "assets/svg";
import abiRegular from "abi/regulars.json";
import ApprovalDialog from "components/Dialog/ApprovalDialog";

import { getNFTById } from "hooks/useNFT";

const CreatePool = function () {
  const steps = ["Select collection", "Select NFTs", "Set Pool Funds", "Create Pool"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedRegistry, setSelectedRegistry] = React.useState("");
  const { library, account, chainId } = useActiveWeb3React();
  const [nfts, setNFTs] = React.useState<any>([]);
  const [selectedTokensIndex, setSelectedTokensIndex] = React.useState<any>([]);
  const [payableAmount, setPayableAmount] = React.useState(0);
  const [buybackPrice, setBuybackPrice] = React.useState(0);
  const [randomSwap, setRandomSwap] = React.useState(false);
  const [fee, setFee] = React.useState(0);
  const [isTxGoing, setIsTxGoing] = React.useState(false);
  const [registryName, setRegistryName] = React.useState("");
  const [notify, setNotify] = React.useState<any>(null);
  const [nftCollectionInfo, setNftCollectionInfo] = React.useState();
  const [isPoolcreated, setIsPoolcreated] = React.useState(false);
  const [showApprovalDialog, setShowApprovalDialog] = React.useState<number | null>(null);
  const [isApproving, setIsApproving] = React.useState(false);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up(720));
  const navigate = useNavigate();

  const signer = library?.getSigner();

  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, signer);

  const getNFTs = async function () {
    if (!account || !selectedRegistry) return [];

    const NFTs = await allNFTsByWallet(account, selectedRegistry);
    return NFTs || [];
    // return NFTs || [];
  };

  const handleNext = async () => {
    if (!activeStep) {
      if (!selectedRegistry) {
        alert("Please input collection address");
        return;
      }
      const res = await contract.registryName(selectedRegistry);
      await setRegistryName(res);
      const tokens = await getNFTs();
      setNFTs(tokens);
    }
    if (activeStep === 3) {
      await createPool();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function onAdd(index: number) {
    if (selectedTokensIndex.indexOf(index) === -1) {
      setSelectedTokensIndex([...selectedTokensIndex, index]);
    } else {
      setSelectedTokensIndex(selectedTokensIndex.filter((item: any) => item !== index));
    }
  }

  const notifyHash = (hash: any, txType: string) => {
    const { emitter } = notify.hash(hash);

    emitter.on("txSent", console.log);
    emitter.on("txPool", console.log);
    emitter.on("txConfirmed", async () => {
      switch (txType) {
        case "approvalForCreatePool": {
          const tx = await contract.createPool(
            selectedRegistry,
            nfts.filter((reg: any, idx: number) => selectedTokensIndex.indexOf(idx) !== -1),
            // BigInt(buybackPrice * 10 ** 18),
            BigInt(fee * 10 ** 18)
          );
          notifyHash(tx.hash, "createPool");
          break;
        }
        case "createPool": {
          setIsPoolcreated(true);
          await fb.addActivity(
            {
              name: "Created Pool",
              data: {
                registry: selectedRegistry,
                registryName,
                owner: account,
                txHash: hash,
                nfts: nfts.filter((reg: any, idx: number) => selectedTokensIndex.indexOf(idx) !== -1),
                buyback: buybackPrice,
                poolFee: fee,
              },
            },
            chainId
          );
          break;
        }
        default:
          break;
      }
    });
    emitter.on("txSpeedUp", console.log);
    emitter.on("txCancel", console.log);
    emitter.on("txFailed", console.log);
  };

  const createPool = async () => {
    const signer = library?.getSigner();
    const nftContract = new ethers.Contract(selectedRegistry, abiRegular, signer);
    const isApproved = await nftContract.isApprovedForAll(account, UGLYPOOL_CONTRACT_ADDRESS);
    if (!isApproved) {
      setShowApprovalDialog(1);
      return;
    }
    setIsTxGoing(true);
    setIsPoolcreated(false);
    try {
      const res = await setApproval(account, library, selectedRegistry, notifyHash, "approvalForCreatePool");
      if (!res) {
        const tx = await contract.createPool(
          selectedRegistry,
          nfts.filter((reg: any, idx: number) => selectedTokensIndex.indexOf(idx) !== -1),
          // BigInt(buybackPrice * 10 ** 18),
          BigInt(fee * 10 ** 18)
        );
        notifyHash(tx.hash, "createPool");
      }
    } catch (e) {
      alert("Something is wrong. Please see console log");
      console.log(e);
      setIsTxGoing(false);
    }
  };

  React.useEffect(() => {
    contract.on("CreatedPool", (poolId: ethers.BigNumber, owner: string) => {
      setIsTxGoing(false);
      // window.location.href = `/pool/${poolId}`;
    });
    if (account && notify === null) {
      const notifyObj = Notify({
        dappId: DAPP_ID,
        networkId: DEFAULT_CHAIN_ID,
        darkMode: true,
        onerror: error => console.log(`Notify error: ${error.message}`),
      });
      setNotify(notifyObj);
    }
    return () => {
      contract.removeAllListeners("CreatedPool");
    };
  }, []);

  const getNFTCollectionDetail = async (address: string) => {
    if (isAddress(address)) {
      const validTokenId = chainId === 1 ? 44 : 1;
      const firstNFT = await getNFTById(contract, address, validTokenId);
      const registryName = await contract.registryName(address);
      const _nftColInfo: any = {
        logo: firstNFT.imageUrl,
        registryName,
        registry: address,
      };
      setNftCollectionInfo(_nftColInfo);
    }
  };

  const approveContract = async (nftId: number) => {
    if (!selectedRegistry) return;
    try {
      const signer = library?.getSigner();
      const nftContract = new ethers.Contract(selectedRegistry, abiRegular, signer);
      const tx = await nftContract.setApprovalForAll(UGLYPOOL_CONTRACT_ADDRESS, true).then((tx: any) => {
        setIsApproving(true);
        return tx.wait();
      });
      if (tx) {
        setShowApprovalDialog(null);
        setIsApproving(false);
        createPool();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ width: "100%", position: "fixed", top: "0px", left: "0px", zIndex: 10, backgroundColor: "black" }}>
      <Container maxWidth="lg">
        <Box sx={{ mt: "30px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "30px", lineHeight: "35px", color: "#333333" }}>Create Pool</Typography>
          <Stack direction="row" gap="12px">
            <Button variant="contained" color="secondary" onClick={() => navigate("/manage")}>
              Cancel
            </Button>
            {activeStep > 0 && (
              <Button variant="contained" color="secondary" onClick={handleBack}>
                Back
              </Button>
            )}
            <Button variant="contained" onClick={handleNext} disabled={isTxGoing}>
              {activeStep === steps.length - 1 ? (isTxGoing ? "Submit ..." : "Submit") : "Next"}
            </Button>
          </Stack>
        </Box>
        <CreatPoolStepper activeStep={activeStep} steps={steps} />
        <Box sx={{ mt: "30px" }}>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {!activeStep && (
                <>
                  <Box sx={{ width: "560px", my: "30px" }}>
                    <Typography sx={{ fontSize: "18px", lineHeight: "21px", pl: "30px" }}>
                      Input Collection Address
                    </Typography>
                    <GrayTextInput
                      sx={{ mt: "8px" }}
                      fullWidth
                      value={selectedRegistry}
                      onChange={e => {
                        setSelectedRegistry(e.target.value);
                        getNFTCollectionDetail(e.target.value);
                      }}
                    />
                  </Box>
                  {nftCollectionInfo && <NFTCollectionInfoBox collectionInfo={nftCollectionInfo} />}
                </>
              )}

              {/* Select NFTs */}
              {activeStep === 1 && (
                <>
                  <Typography
                    sx={{ fontSize: "16px", lineHeight: "24px", letterSpacing: "0.5px", color: "#828282", my: "30px" }}
                  >
                    Select NFTs from your wallet{" "}
                    <Typography component="span" sx={{ color: "#333333" }}>
                      {formatAddress(account || "")}
                    </Typography>{" "}
                    to add to the pool:{" "}
                    <Typography component="span" sx={{ color: "#2F80ED" }}>
                      {selectedTokensIndex.length} Selected
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                        color: "#828282",
                      }}
                    >
                      Images take a moment to load from IPFS
                    </Typography>
                  </Typography>

                  <Box
                    gap="21px"
                    display="flex"
                    flexWrap="wrap"
                    pb="10px"
                    justifyContent={isMedium ? "initial" : "center"}
                    style={{ maxHeight: "820px", overflow: "auto" }}
                  >
                    {nfts.map((item: number, index: number) => (
                      <NFTCard
                        key={index}
                        registryAddress={selectedRegistry}
                        registryName={registryName}
                        tokenId={item}
                        maxWidth={225}
                        showSelect
                        selected={selectedTokensIndex.indexOf(index) !== -1}
                        onSelect={() => onAdd(index)}
                      />
                    ))}
                  </Box>
                </>
              )}

              {/* Set Eth amount, buyback price, random */}
              {activeStep === 2 && (
                <Stack gap="30px">
                  {/* <Stack direction="row" alignItems="center" gap="30px" sx={{ opacity: 0.5 }}>
                    <Typography sx={{ fontSize: "18px", width: "164px" }}>Treasury Deposit (Ξ) </Typography>
                    <GrayTextInput
                      disabled
                      type="number"
                      value={payableAmount}
                      onChange={e => {
                        setPayableAmount(Number(e.target.value));
                      }}
                    />
                    <Typography sx={{ color: "#2F80ED", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.5px" }}>
                      This is the total ETH liquidity in your pool that will buyback NFTs sold to your pool.
                      <br /> You can deposit any amount or withdraw the entire fund at any time, with gas.
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="30px" sx={{ opacity: 0.5 }}>
                    <Typography sx={{ fontSize: "18px", width: "164px" }}>Buyback Price (Ξ) </Typography>
                    <GrayTextInput
                      disabled
                      type="number"
                      value={buybackPrice}
                      onChange={e => {
                        setBuybackPrice(Number(e.target.value));
                      }}
                    />
                    <Typography sx={{ color: "#2F80ED", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.5px" }}>
                      This is the price at which users can sell their NFT to your pool for. <br />
                      You can change this price at any time, with gas.
                    </Typography>
                  </Stack> */}

                  <Stack direction="row" gap="30px">
                    <Typography sx={{ fontSize: "18px", width: "164px" }}>Swap Type </Typography>
                    <Stack>
                      <Stack direction="row" gap="30px">
                        {/* <Box
                          sx={{
                            opacity: 0.5,
                            width: "424px",
                            border: "solid 1px",
                            borderColor: randomSwap ? "#2F80ED" : "#828282",
                            height: "142px",
                            borderRadius: "20px",
                            p: "12px",
                            color: "#828282",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: randomSwap ? "#2F80ED" : "inherit",
                            }}
                          >
                            {randomSwap ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                            <Typography component="span" sx={{ ml: "10px" }}>
                              Random Swap
                            </Typography>
                          </Typography>
                          <Typography sx={{ fontSize: "16px", lineHeight: "22px", mt: "10px" }}>
                            Users swap for any random NFT from your pool.
                            <br /> There are no trade fees involved in random swaps.
                          </Typography>
                        </Box> */}
                        <Box
                          sx={{
                            width: "424px",
                            border: "solid 1px",
                            borderColor: randomSwap ? "#828282" : "#2F80ED",
                            height: "142px",
                            borderRadius: "20px",
                            p: "12px",
                            color: "#828282",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setRandomSwap(false);
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: !randomSwap ? "#2F80ED" : "inherit",
                            }}
                          >
                            {!randomSwap ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                            <Typography component="span" sx={{ ml: "10px" }}>
                              Selectable Swap
                            </Typography>
                          </Typography>
                          <Typography sx={{ fontSize: "16px", lineHeight: "22px", mt: "10px" }}>
                            Users swap for an NFT of their selection from the <br />
                            pool. Users are charged a trade fee, determined by <br />
                            the pool owner. 10% of the trade fee goes to the <br />
                            contract treasury
                          </Typography>
                        </Box>
                      </Stack>
                      {/* <Typography sx={{ color: "#EB5757", mt: "5px", lineHeight: "30px", fontSize: "16px" }}>
                        Treasury, Buyback and Random Swap are currently disabled.
                      </Typography> */}
                    </Stack>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="30px" sx={{ color: "#828282" }}>
                    <Typography sx={{ fontSize: "18px", width: "164px" }}>Trade Fee (Ξ) </Typography>
                    <GrayTextInput
                      type="number"
                      value={fee}
                      onChange={e => {
                        setFee(Number(e.target.value));
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                        color: fee > 0 ? "#2F80ED" : "inherit",
                      }}
                    >
                      This is the fee users pay in your selectable swap pool. 10% goes to the contract treasury. <br />
                      You can change this price at any time, with gas.
                    </Typography>
                  </Stack>
                </Stack>
              )}

              {/* overivew of pool */}
              {activeStep === 3 && (
                <Box>
                  {nftCollectionInfo && <NFTCollectionInfoBox collectionInfo={nftCollectionInfo} />}
                  <PoolDetailInfoBox
                    pool={{
                      id: 0,
                      owner: account || "",
                      treasury: payableAmount,
                      price: buybackPrice,
                      fee,
                      random: randomSwap,
                      nfts: selectedTokensIndex,
                    }}
                    account={account || ""}
                  />

                  <Typography sx={{ fontWeight: 700, fontSize: "18px", my: "30px" }}>All NFTs in this pool</Typography>

                  <Box
                    gap="21px"
                    display="flex"
                    flexWrap="wrap"
                    mt="30px"
                    pb="10px"
                    justifyContent={isMedium ? "initial" : "center"}
                    style={{ maxHeight: "820px", overflow: "auto" }}
                  >
                    {nfts
                      .filter((reg: any, idx: number) => selectedTokensIndex.indexOf(idx) !== -1)
                      .map((id: number) => (
                        <NFTCard
                          key={id}
                          registryAddress={selectedRegistry}
                          registryName={registryName}
                          tokenId={id}
                          showSelect
                        />
                      ))}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
      <Dialog
        open={isTxGoing}
        onClose={() => {
          console.log("close dialog");
        }}
      >
        <Box sx={{ width: "551px" }}>
          {!isPoolcreated && (
            <>
              <Typography sx={{ textAlign: "center", fontWeight: "500", fontSize: "30px" }}>
                Your pool is being created
              </Typography>
              <Box sx={{ width: "100%", height: 100, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={40} thickness={5} />
              </Box>
              <Typography sx={{ color: "#2F80ED", fontWeight: "700", fontSize: "18px", textAlign: "center" }}>
                Processing Transation...
              </Typography>
            </>
          )}
          {isPoolcreated && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "30px" }}>
                <Typography
                  sx={{
                    alignItems: "center",
                    color: "#333333",
                    fontWeight: "500",
                    fontSize: "30px",
                    lineHeight: "35px",
                  }}
                >
                  Your pool has been created
                </Typography>

                <IconButton
                  onClick={() => {
                    setIsPoolcreated(false);
                    setIsTxGoing(false);
                  }}
                >
                  <Box component="img" src={icon_close} />
                </IconButton>
              </Box>

              <Button variant="contained" sx={{ width: "100%" }} onClick={() => navigate("/manage")}>
                See My Pools
              </Button>
            </>
          )}
        </Box>
      </Dialog>
      {showApprovalDialog !== null && (
        <ApprovalDialog
          onClose={() => {
            setShowApprovalDialog(null);
            setIsApproving(false);
          }}
          onConfirm={() => approveContract(showApprovalDialog)}
          isConfirming={isApproving}
        />
      )}
    </Box>
  );
};
export default CreatePool;
