import { useEffect, useState } from "react";
import { Container, Box, useMediaQuery, Button, Typography, IconButton } from "@mui/material";
import { ethers } from "ethers";
import { useActiveWeb3React } from "hooks/web3";
import { useTheme } from "@mui/system";
import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "abi/uglypool.json";
import Dialog from "components/Dialog";
import NFTCard from "components/NFTCard";

import { icon_arrow_left, icon_tag, icon_swap, icon_cross_swap } from "assets/svg";

import { formatAddress } from "utils";
import { ChevronLeft } from "@mui/icons-material";
import { logAnalytics } from "lib/firebase";
import { allNFTsByWallet } from "utils/helper";

interface Props {
  visible: boolean;
  contractAddress: string;
  action: string;
  heading: { text: string; bold?: string };
  poolNFTs: number[];
  onClickBackBtn: () => void;
  onChooseNFT: (nft: any) => void;
}

const Index = function ({ visible, action, heading, contractAddress, poolNFTs, onChooseNFT, onClickBackBtn }: Props) {
  const [nfts, setNFTs] = useState<any>([]);
  const [registryName, setRegistryName] = useState("");
  const [selectedMyNfts, selectMyNfts] = useState<any>([]);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up(720));

  const { library, account } = useActiveWeb3React();
  const signer = library?.getSigner();
  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, signer);

  const getNFTs = async function (account: string, contractAddress: string) {
    const NFTs = await allNFTsByWallet(account, contractAddress);
    return NFTs || [];
  };

  const loadMyNFT = async (account: string, contractAddress: string) => {
    if (!contractAddress) {
      alert("Please input collection address");
      return;
    }
    const res = await contract.registryName(contractAddress);
    setRegistryName(res);
    const tokens = await getNFTs(account, contractAddress);
    setNFTs(tokens);
  };

  useEffect(() => {
    if (account) {
      loadMyNFT(account, contractAddress);
    }
  }, [account, visible]);

  useEffect(() => {
    selectMyNfts([]);
  }, [visible]);

  const isMultipleSelectable = action === "withdraw" || action === "deposit";

  return (
    <Dialog
      maxWidth="1280px"
      open={visible}
      onClose={() => {
        onClickBackBtn();
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            mt: "30px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              textTransform: "none",
              "&:hover": { backgroundColor: "unset" },
              color: "#e2e2e6",
              fontWeight: "500",
              fontSize: "30px",
              lineHeight: "35px",
              flexWrap: "wrap",
            }}
          >
            <>
              <Box>
                <IconButton
                  onClick={() => {
                    logAnalytics("nft_pick_canceled");
                    onClickBackBtn();
                  }}
                >
                  <ChevronLeft />
                </IconButton>
                {heading.text} &nbsp;
              </Box>
              {heading.bold && (
                <Typography
                  component="span"
                  sx={{
                    color: "#2F80ED",
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    lineHeight: "inherit",
                    display: "inline-block",
                  }}
                >
                  {heading.bold}
                </Typography>
              )}
            </>
          </Box>

          {action === "sell" && (
            <Button
              variant="contained"
              style={{
                padding: "14px 27px",
                height: "auto",
                fontSize: "18px",
                borderRadius: "26px",
              }}
              startIcon={<Box component="img" src={icon_tag} />}
              onClick={() => {
                if (selectedMyNfts.length === 1) {
                  logAnalytics("sell_now_clicked");
                  onChooseNFT(selectedMyNfts[0]);
                }
              }}
            >
              Sell Now
            </Button>
          )}
          {action === "swap" && (
            <Button
              variant="contained"
              style={{
                padding: "14px 27px",
                height: "auto",
                fontSize: "18px",
                borderRadius: "26px",
              }}
              startIcon={<Box component="img" src={icon_swap} />}
              onClick={() => {
                if (selectedMyNfts.length === 1) {
                  logAnalytics("swap_now_clicked");
                  onChooseNFT(selectedMyNfts[0]);
                }
              }}
            >
              Swap Now
            </Button>
          )}
          {action === "random-swap" && (
            <Button
              variant="contained"
              style={{
                padding: "14px 27px",
                height: "auto",
                fontSize: "18px",
                borderRadius: "26px",
              }}
              startIcon={<Box component="img" src={icon_cross_swap} />}
              onClick={() => {
                if (selectedMyNfts.length === 1) {
                  logAnalytics("random_swap_now_clicked");
                  onChooseNFT(selectedMyNfts[0]);
                }
              }}
            >
              Random Swap Now
            </Button>
          )}
          {action === "deposit" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "18px", color: "#2F80ED" }}>{selectedMyNfts.length} selected</Typography>
              <Button
                variant="contained"
                sx={{ ml: "14px" }}
                disabled={!selectedMyNfts.length}
                onClick={() => {
                  logAnalytics("add_nfts_clicked");
                  onChooseNFT(selectedMyNfts);
                }}
              >
                Add NFTs
              </Button>
            </Box>
          )}
          {action === "withdraw" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "18px", color: "#2F80ED" }}>{selectedMyNfts.length} selected</Typography>
              <Button
                variant="contained"
                sx={{ ml: "14px" }}
                disabled={!selectedMyNfts.length}
                onClick={() => {
                  logAnalytics("withdraw_nfts_clicked");
                  onChooseNFT(selectedMyNfts);
                }}
              >
                Withdraw NFTs
              </Button>
            </Box>
          )}
        </Box>
        <Typography sx={{ fontSize: "16px", lineHeight: "24px", color: "#828282", mt: "9px" }}>
          Select NFT{(action === "deposit" || action === "withdraw") && "s"} from your wallet
          <Typography component="span" sx={{ color: "#e2e2e6", mx: "8px" }}>
            {formatAddress(account || "")}
          </Typography>
          to &nbsp;
          {action === "random-swap" && <>swap to the pool</>}
          {action === "swap" && <>swap to the pool</>}
          {action === "sell" && <>sell to the pool</>}
          {action === "deposit" && <>add to the pool</>}
          {action === "withdraw" && <>withdraw from the pool</>}
        </Typography>

        <Box gap="21px" display="flex" flexWrap="wrap" mt="20px" pb="10px" justifyContent="center">
          {action !== "withdraw"
            ? nfts.map((item: number) => (
                <NFTCard
                  onGrid
                  key={item}
                  registryAddress={contractAddress}
                  registryName={registryName}
                  tokenId={item}
                  maxWidth={225}
                  showSelect
                  selected={selectedMyNfts.indexOf(item) > -1}
                  onSelect={() => {
                    const index = selectedMyNfts.indexOf(item);
                    if (index > -1) {
                      selectedMyNfts.splice(index, 1);
                    } else if (isMultipleSelectable) {
                      selectedMyNfts.push(item);
                    } else {
                      selectMyNfts([item]);
                      return;
                    }
                    selectMyNfts([...selectedMyNfts]);
                  }}
                />
              ))
            : poolNFTs.map((item: number) => (
                <NFTCard
                  onGrid
                  key={item}
                  registryAddress={contractAddress}
                  registryName={registryName}
                  tokenId={item}
                  maxWidth={225}
                  showSelect
                  selected={selectedMyNfts.indexOf(item) > -1}
                  onSelect={() => {
                    const index = selectedMyNfts.indexOf(item);
                    if (index > -1) {
                      selectedMyNfts.splice(index, 1);
                    } else if (isMultipleSelectable) {
                      selectedMyNfts.push(item);
                    } else {
                      selectMyNfts([item]);
                      return;
                    }
                    selectMyNfts([...selectedMyNfts]);
                  }}
                />
              ))}
        </Box>
      </Container>
    </Dialog>
  );
};

export default Index;
