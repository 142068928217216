import { CircularProgress, Box, Typography } from "@mui/material";

interface Props {
  text: string;
}

const Loader = function ({ text }: Props) {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        background: "black",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <Typography ml={2} variant="h5" color="palette.primary.dark">
        {text}
      </Typography>
    </Box>
  );
};
export default Loader;
