import * as React from "react";
import { useActiveWeb3React } from "hooks/web3";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    borderRadius: "26px",
    padding: "15px 27px",
    fontSize: "18px",
    color: "white",
    fontWeight: 500,
    textTransform: "none",
  },
}));

const WalletMenu = function () {
  const navigate = useNavigate();
  const classes = useStyles();
  const { account, library } = useActiveWeb3React();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ensName, setEnsName] = React.useState("");
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/my-activity");
    // setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    (async () => {
      setEnsName((await library?.lookupAddress(account || "")) || account || "");
    })();
  }, [account]);

  return (
    <div>
      <Button
        id="basic-button"
        className={classes.menuButton}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {isMedium ? <MenuIcon /> : formatWalletAddress(ensName)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            background: "#232222",
            width: "168px",
            color: "white",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/manage");
          }}
        >
          My Pools
        </MenuItem>
        {isMedium && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/activity");
            }}
          >
            Activity
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default WalletMenu;

export const formatWalletAddress = (address: string) => {
  console.log(address);
  if (address.length < 20) return address;
  return `${address.slice(0, 8)}...${address.slice(address.length - 6, address.length)}`;
};
