import { useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@mui/styles";

import WalletMenu from "components/Menu/WalletMenu";
import { injected, walletconnect } from "../../connectors";
import { useActiveWeb3React } from "../../hooks/web3";

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    borderRadius: "26px",
    padding: "15px 24px",
    fontSize: "18px",
    color: "white",
    fontWeight: 500,
    textTransform: "none",
  },
}));

const ConnectWalletButton = function () {
  const { account, activate } = useActiveWeb3React();

  const classes = useStyles();

  useEffect(() => {
    if (account) {
      /* */
    }
  }, [account]);

  const handleConnect = useCallback(async () => {
    if (isMobile) {
      window.location.assign("https://metamask.app.link/dapp/uglypool.xyz");
      return;
    }
    const connector = isMobile ? walletconnect : injected;
    await activate(connector, (err: any) => {
      console.log(err);
    });
  }, [activate]);

  if (!account) {
    return (
      <Button className={classes.menuButton} onClick={handleConnect}>
        Connect To Wallet
      </Button>
    );
  }

  return <WalletMenu />;
};

export default ConnectWalletButton;
