import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useActiveWeb3React } from "hooks/web3";
import { useTheme } from "@mui/system";
import { COLLECTIONS_WITH_FIREBASE_IMAGES, UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "abi/uglypool.json";
import { ethers } from "ethers";
import { getNFTById } from "hooks/useNFT";
import { getCollectionLogo, PoolObject } from "utils/helper";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import TableCellHeader from "components/TableCellHeader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import StatusIcon from "components/StatusIcon";
import { classNames } from "utils";
import Loader from "components/Loader";
import NFTCollectionInfoBox from "components/NFTCollectionInfoBox";
import { logAnalytics } from "lib/firebase";

export interface PoolsArr {
  [key: string]: PoolObject[];
}

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontSize: "18px",
  },
  tableCell: {
    fontSize: "18px",
    fontWeight: 400,
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#2a2a2a",
      "& $arrowRightIcon": {
        color: "#000",
      },
    },
    borderColor: "#BDBDBD",
  },
  arrowRightIcon: {
    fontSize: 14,
    marginLeft: "auto",
    marginRight: 0,
    color: "#BDBDBD",
  },
  ownerENS: {
    "&$active": {
      color: "#2F80ED",
      position: "relative",
      alignItems: "center",
      display: "flex",
      "&::before": {
        left: -10,
        position: "absolute",
        content: '""',
        backgroundColor: "#2F80ED",
        borderRadius: "50%",
        width: 5,
        height: 5,
        display: "block",
      },
    },
  },
  active: {},
}));

const Pools = function ({
  isMine = false,
  withFeatured,
  pools,
  setPools,
}: {
  isMine?: boolean;
  withFeatured?: number[];
  pools: PoolsArr;
  setPools: any;
}) {
  const { library, account, chainId } = useActiveWeb3React();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const classes = useStyles();

  React.useEffect(() => {
    if (!account) return;
    setPools({});
    setIsLoading(true);
    const getPools = async () => {
      const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, library);
      let poolIds: number[] = [];
      if (isMine) {
        poolIds = await contract
          .poolIdsByOwner(account)
          .then((ids: ethers.BigNumber[]) =>
            ids.filter((id: ethers.BigNumber) => id.toNumber() > 0).map((id: ethers.BigNumber) => id.toNumber())
          )
          .catch((e: Error) => console.log(e));
      } else {
        const poolCnt = await contract
          .numPools()
          .then((cnt: ethers.BigNumber) => cnt.toNumber())
          .catch((e: Error) => console.log(e));
        poolIds = Array.from({ length: poolCnt - 1 }, (_, i) => i + 1);
      }
      await poolIds
        .filter((id: number) => !withFeatured || withFeatured.indexOf(id) > -1)
        ?.map(async (id: number) => {
          const pool = await contract.pools(id);
          const nfts = contract
            .getPoolNFTIds(id)
            .then((ids: ethers.BigNumber[]) => ids.map((id: ethers.BigNumber) => id.toNumber()))
            .catch((e: Error) => console.log(e));
          const registryName = contract.registryName(pool.registry);
          const validTokenId = chainId === 1 ? 44 : 1;
          // if we have added a collection logo use that instead of NFT image
          let firstNFT: any = getNFTById(contract, pool.registry, validTokenId);
          if (pool.registry && COLLECTIONS_WITH_FIREBASE_IMAGES[pool.registry]) {
            console.log(4);
            firstNFT = {
              imageUrl: getCollectionLogo(COLLECTIONS_WITH_FIREBASE_IMAGES[pool.registry]),
            };
          }
          const ownerENS = library?.lookupAddress(pool.owner || "");
          await Promise.all([nfts, registryName, firstNFT, ownerENS]).then((val: any) => {
            setPools((prevState: any) => {
              const poolArr = prevState[pool.registry] || [];
              poolArr.push({
                ...pool,
                id,
                treasury: pool.treasury / 10 ** 18,
                price: pool.price / 10 ** 18,
                fee: pool.fee / 10 ** 18,
                nfts: val[0],
                registryName: val[1],
                logo: val[2].imageUrl,
                ownerENS: val[3] || pool.owner || "",
              });
              return {
                ...prevState,
                [pool.registry]: poolArr,
              };
            });
          });
        });
      setIsLoading(false);
    };
    getPools();
  }, [isMine, account, chainId, library, setPools, withFeatured]);

  const formatString = (value: number, pool: any) => {
    if (value === 0) {
      return <Typography sx={{ color: "#333333" }}>-</Typography>;
    }
    return value;
  };
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));

  return (
    <>
      {isLoading && <Loader text="Loading pools..." />}
      {Object.keys(pools).map((key: string, idx: number) => (
        <Box key={idx} mb="60px">
          <NFTCollectionInfoBox
            collectionInfo={{
              logo: pools[key][0].logo,
              registry: pools[key][0].registry,
              registryName: pools[key][0].registryName,
            }}
          />
          <TableContainer key={idx}>
            <Table sx={{ minWidth: isMedium ? 0 : 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>Owner</TableCell>
                  <TableCell className={classes.tableHeaderCell} align="right">
                    NFTs
                  </TableCell>
                  {isMedium ? null : (
                    <>
                      <TableCellHeader label="Buyback(eth)" description="Buyback(eth)" />
                      <TableCellHeader
                        label="Treasury(eth)"
                        description="Treasury indicates the amount of eth currently available in the pool for NFT buybacks"
                      />
                      <TableCellHeader label="Trade Fee" description="Trade Fee" />
                    </>
                  )}

                  <TableCellHeader label="Swap Type" description="Swap Type" />
                </TableRow>
              </TableHead>
              <TableBody>
                {pools[key].map((pool: PoolObject) => (
                  <TableRow
                    key={pool.id}
                    className={classes.row}
                    onClick={() => {
                      navigate(`/pool/${pool.id}`);
                    }}
                  >
                    <TableCell className={classes.tableCell}>
                      <Typography
                        component="div"
                        className={classNames(
                          account?.toString().toLocaleLowerCase() === pool.ownerENS?.toString().toLocaleLowerCase()
                            ? classes.active
                            : "",
                          classes.ownerENS
                        )}
                      >
                        {pool.ownerENS && pool.ownerENS.length > 20
                          ? `${pool.ownerENS.slice(0, 8)}...${pool.ownerENS.slice(
                              pool.ownerENS.length - 6,
                              pool.ownerENS.length
                            )}`
                          : pool.ownerENS}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {pool.nfts?.length}
                    </TableCell>
                    {isMedium ? null : (
                      <>
                        <TableCell className={classes.tableCell} align="center">
                          {pool.price > 0 ? `${pool.price} Ξ` : <Typography sx={{ color: "#333333" }}>-</Typography>}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            {pool.treasury > 0 ? (
                              <>
                                {pool.treasury}&nbsp;&nbsp;Ξ&nbsp;&nbsp;
                                {pool.treasury > pool.price ? (
                                  <StatusIcon
                                    icon={
                                      <CheckCircleIcon sx={{ color: "#27AE60", cursor: "pointer", fontSize: "20px" }} />
                                    }
                                    description={`${(pool.treasury / pool.price).toFixed(0)} buybacks available`}
                                  />
                                ) : (
                                  <CancelIcon sx={{ color: "#EB5757", fontSize: "20px" }} />
                                )}
                              </>
                            ) : (
                              <Typography sx={{ color: "#333333" }}>-</Typography>
                            )}

                            {/* {pool.treasury}&nbsp;&nbsp;Ξ&nbsp;&nbsp;
                        {pool.treasury > pool.price ? (
                          <CheckCircleIcon sx={{ color: "#27AE60" }} />
                        ) : (
                          <CancelIcon sx={{ color: "#EB5757" }} />
                        )} */}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {pool.fee} Ξ
                        </TableCell>
                      </>
                    )}

                    <TableCell
                      className={classes.tableCell}
                      sx={{ display: "flex", alignItems: "center" }}
                      align="center"
                    >
                      {pool.random ? "Random" : "Selectable"}
                      <ArrowForwardIosIcon className={classes.arrowRightIcon} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
      {!isLoading && account && isMine && (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              logAnalytics("create_new_pool_clicked");
              navigate("/create-pool");
            }}
          >
            Create Pool
          </Button>
        </div>
      )}
      {!isLoading && !account && (
        <Typography textAlign="center" variant="h3">
          Wallet not connected
        </Typography>
      )}
    </>
  );
};
export default Pools;
