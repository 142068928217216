import { useState, useEffect } from "react";
import { ethers } from "ethers";
import abiUglyPool from "abi/uglypool.json";
import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import { useActiveWeb3React } from "hooks/web3";
import { getNFTFirebaseImgHashed } from "utils/helper";

export const getNFTById = async (contract: any, registryAddress: string, tokenId: number) => {
  if (
    registryAddress.toLowerCase() === "0x3ead1a2adfe8a30f5314054d9fb3eae136e144a5" ||
    registryAddress.toLowerCase() === "0x33cbb1e55a4f996ba665c6b907def21b26d7b104"
  ) {
    return {
      tokenId,
      name: `Grid Gang #${tokenId}`,
      imageUrl: getNFTFirebaseImgHashed("thegridgang", tokenId),
    };
  }
  const tokenURI = await contract.tokenURI(registryAddress, tokenId);
  // if its dankbots they have custom server instead of ipfs
  if (registryAddress.toLowerCase() === "0x1821d56d2f3bc5a5aba6420676a4bbcbccb2f7fd") {
    const res = await fetch(`https://whitelists.regulars.info/dankbot?url=${encodeURIComponent(tokenURI)}`);
    const tokenObj = await res.json();

    return {
      tokenId,
      name: tokenObj.name,
      imageUrl: tokenObj.image,
    };
  }
  let res;
  if (tokenURI.includes("ipfs://")) {
    const tokenHash = tokenURI.replace("ipfs://", "");
    res = await fetch(`https://ipfs.io/ipfs/${tokenHash}`);
  } else {
    res = await fetch(tokenURI);
  }
  const tokenObj = await res.json();

  const imageUrl = tokenObj.image.includes("ipfs://")
    ? `https://ipfs.io/ipfs/${tokenObj.image.replace("ipfs://", "")}`
    : tokenObj.image;

  return {
    tokenId,
    name: tokenObj.name,
    imageUrl,
  };
};

const useNFT = (registryAddress: string, tokenId: number) => {
  const { library } = useActiveWeb3React();
  const [nft, setNft] = useState<any>(null);

  useEffect(() => {
    let isSubscribed = true;
    const getNFT = async () => {
      const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, library);
      try {
        const nft: any = await getNFTById(contract, registryAddress, tokenId);
        if (isSubscribed) {
          setNft(nft);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (tokenId != null && tokenId > 0) {
      getNFT();
    }

    return () => {
      isSubscribed = false;
    };
  }, [library, tokenId, registryAddress]);
  return nft;
};
export default useNFT;
