import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as fb from "lib/firebase";
import { Box, Button, Icon, Avatar } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import moment from "moment";
import { useActiveWeb3React } from "hooks/web3";
import { makeStyles } from "@mui/styles";
import { formatWalletAddress } from "components/Menu/WalletMenu";
import { COLLECTIONS_WITH_FIREBASE_IMAGES } from "config/misc";
import { getNFTFirebaseImage } from "utils/helper";

const activityIcons: any = {
  "Random Swap": ShuffleIcon,
  "Selected Swap": SwapHorizIcon,
  Buyback: LocalOfferIcon,
};

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    padding: "15px 27px",
    backgroundColor: "black",
    color: "white",
    fontSize: "18px",
  },
  active: {
    backgroundColor: "white",
    padding: "15px 27px",
    color: "black",
    fontWeight: 700,
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

const transformWalletToENS = (activities: any, library: any) =>
  Promise.all(
    activities.map(async (activity: any) => ({
      ...activity,
      data: {
        ...activity.data,
        owner: (await library?.lookupAddress(activity.data.owner || "")) || activity.data.owner || "",
      },
    }))
  );

const Activity = function ({ activities, setActivities, currentTab = "all" }: any) {
  const { account, chainId, library } = useActiveWeb3React();
  const [activityLoaded, setActivityLoaded] = React.useState<any>(false);
  const [ENSDomain, setENSDomain] = React.useState<any>(null);
  const classes = useStyles();

  React.useEffect(() => {
    library?.lookupAddress(account || "").then(acc => setENSDomain(acc));
  }, [account]);

  React.useEffect(() => {
    (async () => {
      try {
        const activities = await fb.getActivities(chainId);
        setActivityLoaded(true);
        setActivities(chainId === 1 ? await transformWalletToENS(activities, library) : activities);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [account, chainId, setActivities]);

  return (
    <Box>
      {(!activities ||
        activities.filter((activity: any) => {
          if (currentTab !== "all") {
            return activity.data.owner === account || activity.data.owner === ENSDomain;
          }
          return true;
        }).length === 0) &&
        activityLoaded && (
          <Box my="30px" textAlign="center">
            You have no recent activity
          </Box>
        )}
      {activities &&
        activities.filter((activity: any) => {
          if (currentTab !== "all") {
            return activity.data.owner === account || activity.data.owner === ENSDomain;
          }
          return true;
        }).length > 0 && (
          <TableContainer>
            <Table
              sx={{
                color: "rgba(255,255,255,0.75)",
                minWidth: 650,
                borderRadius: "17px",
                background: "#141111",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "1px solid #424242",
                  paddingY: "18px",
                  color: "rgba(255,255,255,0.75)",
                },
                [`& tr:last-child .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ color: "red", borderBottom: "1px solid #424242" }}>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ color: "#828282", fontSize: "16px" }}>Pool</TableCell>
                  <TableCell style={{ color: "#828282", fontSize: "16px" }}>Received</TableCell>
                  <TableCell style={{ color: "#828282", fontSize: "16px" }}>Deposited</TableCell>
                  <TableCell style={{ color: "#828282", fontSize: "16px" }}>Address</TableCell>
                  <TableCell style={{ color: "#828282", fontSize: "16px" }}>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities
                  .filter((activity: any) => {
                    if (currentTab !== "all") {
                      return activity.data.owner === account || activity.data.owner === ENSDomain;
                    }
                    return true;
                  })
                  .map((activity: any) =>
                    activity.name.includes("Swap") ? (
                      <React.Fragment key={`${activity.txHash} ${activity.data.myNFT}`}>
                        <TableRow>
                          <TableCell style={{ fontSize: "16px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            >
                              <Icon component={activityIcons[activity.name]} />
                            </Box>
                          </TableCell>
                          <TableCell style={{ fontSize: "16px" }}>{activity.data.registryName}</TableCell>
                          <TableCell style={{ fontSize: "16px" }}>
                            <Box display="flex" flexDirection="row" alignItems="center" sx={{ fontSize: "16px" }}>
                              <Avatar
                                src={
                                  retrieveNFTImage(activity.data.registry, activity.data.myNFT) ||
                                  activity.data.myNFTImage
                                }
                                sx={{ mr: 2, width: 56, height: 56, borderRadius: "10px" }}
                                variant="rounded"
                              />
                              {activity.data.registryName} #{activity.data.myNFT}
                            </Box>
                          </TableCell>
                          <TableCell style={{ fontSize: "16px" }}>
                            <Box display="flex" flexDirection="row" alignItems="center" sx={{ fontSize: "16px" }}>
                              <Avatar
                                src={
                                  retrieveNFTImage(activity.data.registry, activity.data.poolNFT) ||
                                  activity.data.poolNFTImage
                                }
                                sx={{ mr: 2, width: 56, height: 56, borderRadius: "10px" }}
                                variant="rounded"
                              />
                              {activity.data.registryName} #{activity.data.poolNFT}
                            </Box>
                          </TableCell>
                          <TableCell style={{ fontSize: "16px" }}>{formatWalletAddress(activity.data.owner)}</TableCell>
                          <TableCell style={{ fontSize: "16px" }}>
                            {moment(new Date(activity.createdAt.seconds * 1000)).fromNow()}{" "}
                            <Link
                              href={`https://${chainId === 1 ? "" : "rinkeby."}etherscan.io/tx/${activity.data.txHash}`}
                              target="_blank"
                              rel="noopener"
                              style={{ verticalAlign: "text-top" }}
                            >
                              <Icon
                                component={OpenInNewIcon}
                                sx={{ fontSize: "20px", color: "rgba(255,255,255,0.75)" }}
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ) : (
                      <TableRow key={`${activity.txHash} ${activity.data.myNFT}`}>
                        <TableCell>
                          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                            {activity.name in activityIcons && <Icon component={activityIcons[activity.name]} />}
                            <Box>{activity.name}</Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {activity.name.includes("Deposit") || activity.name.includes("Withdraw")
                            ? activity.name.includes("Deposit")
                              ? "+"
                              : "-"
                            : ""}{" "}
                          {activity.name.includes("Eth") || activity.name.includes("Set")
                            ? `${activity.data.amount} Ξ`
                            : `${activity.data.nfts.length} NFT(s)`}
                        </TableCell>
                        <TableCell>
                          {activity.name.includes("Withdraw") ? `${activity.data.registryName}` : activity.data.owner}
                        </TableCell>
                        <TableCell>
                          {activity.name.includes("Deposit")
                            ? `${activity.data.registryName}`
                            : activity.name.includes("Create") || activity.name.includes("Set")
                            ? ""
                            : activity.data.owner}
                        </TableCell>
                        <TableCell>{moment(new Date(activity.createdAt.seconds * 1000)).fromNow()} </TableCell>
                        <TableCell>
                          <Link
                            href={`https://rinkeby.etherscan.io/tx/${activity.data.txHash}`}
                            target="_blank"
                            rel="noopener"
                          >
                            <Icon component={OpenInNewIcon} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Box>
  );
};
export default Activity;

const retrieveNFTImage = (registryAddress: string, tokenId: number) =>
  COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress]
    ? getNFTFirebaseImage(COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress], tokenId)
    : null;
