import { Card, CardContent, CardMedia, useTheme, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import useNFT from "hooks/useNFT";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { COLLECTIONS_WITH_FIREBASE_IMAGES } from "config/misc";
import { getNFTFirebaseImage } from "utils/helper";

const NFTCard = function ({
  registryAddress,
  tokenId,
  selected,
  onSelect,
  showSelect = false,
  maxWidth = 225,
  unselectable = false,
  onGrid = false,
}: any) {
  const nft = useNFT(registryAddress, tokenId);
  const nftImage = COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress]
    ? getNFTFirebaseImage(COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress], tokenId)
    : nft?.imageUrl;

  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.down(1173));
  const isMD = useMediaQuery(theme.breakpoints.down(950));
  const isSM = useMediaQuery(theme.breakpoints.down(750));
  const isXS = useMediaQuery(theme.breakpoints.down(540));
  const getDynamicWidth = () => {
    if (!onGrid) return "227px";
    if (!isLG) return "calc(20% - 20px)";
    if (!isMD) return "calc(25% - 20px)";
    if (!isSM) return "calc(33% - 20px)";
    if (!isXS) return "calc(50% - 20px)";
    return "100%";
  };

  return (
    <Card
      sx={{
        width: getDynamicWidth(),
        borderRadius: "20px",
        border: selected ? `1px solid #EB5757` : "0px solid #F2F2F2",
        opacity: 0.9,
        "&:hover": {
          opacity: 1,
        },
        boxShadow: "none",
        cursor: "pointer",
        backgroundImage: "none",
        backgroundColor: "#333333",
      }}
      onClick={() => {
        if (showSelect) onSelect();
      }}
    >
      {!nft ? (
        <Skeleton animation="wave" variant="rectangular" />
      ) : (
        <>
          <CardMedia
            width="225px"
            height="225px"
            component="img"
            image={nftImage}
            alt={nft?.name}
            style={{ width: "225px", height: "225px" }}
          />
          <CardContent
            sx={{
              display: "flex",
              padding: "3px 12px",
              paddingBottom: "2px !important",
              alignItems: "center",
              boxShadow: "none",
              gap: "20px",
              backgroundColor: "#333333",
            }}
          >
            <Box sx={{ flexGrow: 1 }} py="8px">
              <Box
                sx={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: "500",
                  color: selected && showSelect ? "#EB5757" : "",
                }}
                display="flex"
              >
                {nft.name}
              </Box>
            </Box>
            {!selected && showSelect && !unselectable && (
              <Box>
                <CheckCircleOutlineIcon sx={{ color: "black", fontSize: "30px" }} />
              </Box>
            )}
            {selected && showSelect && !unselectable && (
              <Box>
                <CheckCircleIcon sx={{ color: "#EB5757", fontSize: "30px" }} />
              </Box>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default NFTCard;
