import { Box, Link, Typography, IconButton, useMediaQuery, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { OpenInNew } from "@mui/icons-material";
import { formatWalletAddress } from "components/Menu/WalletMenu";
import { useTheme } from "@mui/system";
import { useActiveWeb3React } from "hooks/web3";
import Info from "@mui/icons-material/Info";
import { getCollectionLogo } from "utils/helper";
import { icon_swap, icon_tag, icon_cross_swap } from "assets/svg";
import { openSeaUrls } from "config/misc";

interface Props {
  collectionInfo: {
    pool: any;
    logo?: string;
    registry: string;
    registryName?: string;
  };
  isMyPool: boolean;
  openMyNFTPicker: any;
  swapPoolNFT: any;
  currentTab?: string;
  setCurrentTab: any;
}

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    fontSize: "18px",
    backgroundColor: "black",
    color: "white",
    padding: "15px 27px",
    fontWeight: 400,
  },
  active: {
    padding: "15px 27px",
    backgroundColor: "white",
    fontSize: "18px",
    color: "black",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  swapButton: {
    padding: "14px 27px",
    backgroundColor: "#EB5757",
    color: "black",
    fontSize: "18px",
    border: "1px solid #EB5757",
    "&:hover": {
      backgroundColor: "black",
      color: "#EB5757",
      border: "1px solid #EB5757",
    },
    "&:disabled": {
      backgroundColor: "black",
      color: "#EB5757",
      border: "1px solid #EB5757",
      opacity: 0.5,
    },
  },
  toughMenu: {
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(1098)]: {
      "& > div:first-child, & > div:last-child": {
        marginLeft: "auto",
      },
    },
    [theme.breakpoints.down(1097)]: {
      "& > div:nth-child(2)": {
        width: "100%",
        justifyContent: "center",
      },
      "& > div:first-child, & > div:last-child": {
        marginLeft: 0,
      },
    },
  },
}));

const Index = function ({
  collectionInfo,
  isMyPool,
  openMyNFTPicker,
  swapPoolNFT,
  currentTab = "pool",
  setCurrentTab,
}: Props) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));
  const isSmall = useMediaQuery(theme.breakpoints.down(650));
  const isXSmall = useMediaQuery(theme.breakpoints.down(650));
  const { chainId } = useActiveWeb3React();
  const classes = useStyles();
  console.log(collectionInfo?.registry);
  return (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Link
        href={`https://opensea.io/collection/${
          openSeaUrls[collectionInfo?.registry?.toLocaleLowerCase()] || collectionInfo.registryName?.toLocaleLowerCase()
        }`}
        target="_blank"
        sx={{
          textDecoration: "none",
          color: "white",
          textAlign: "center",
        }}
      >
        <img
          style={{
            width: "156px",
            height: "156px",
            borderRadius: "50%",
          }}
          src={getCollectionLogo(collectionInfo.registryName || "")}
          alt={collectionInfo.registryName}
        />

        <Typography
          sx={{
            fontSize: "29px",
            fontWeight: 500,
            width: "100%",
            textAlign: "center",
            marginTop: "5px",
            marginBottom: "20px",
          }}
        >
          {collectionInfo.registryName}
        </Typography>
      </Link>
      <Box display="flex" flexWrap="wrap" gap="20px" className={classes.toughMenu}>
        <Box width="275px" ml="auto" />
        <Box display="flex" flexWrap="wrap" gap="20px" alignItems="flex-start">
          <Button
            className={currentTab === "pool" ? classes.active : classes.menuButton}
            onClick={() => setCurrentTab("pool")}
          >
            {collectionInfo.pool.nfts.length} NFTs
          </Button>
          <Button
            className={currentTab === "activity" ? classes.active : classes.menuButton}
            onClick={() => setCurrentTab("activity")}
          >
            Activity
          </Button>
          <Button
            className={currentTab === "myRegulars" ? classes.active : classes.menuButton}
            onClick={() => setCurrentTab("myRegulars")}
          >
            My {collectionInfo.registryName}
          </Button>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          gap="20px"
          alignItems="center"
          ml="auto"
          justifyContent="center"
          style={{
            visibility: currentTab === "pool" ? "visible" : "hidden",
          }}
        >
          <Typography sx={{ fontSize: "18px", lineHeight: "22px", color: "#EB5757", padding: "15px 27px" }}>
            {collectionInfo.pool.fee ? <>{`${collectionInfo.pool.fee} Ξ`} Fee</> : <>No Fee</>}
          </Typography>
          <Button
            className={classes.swapButton}
            onClick={() => {
              openMyNFTPicker({ action: "swap", visible: true });
            }}
            disabled={swapPoolNFT === -1}
          >
            Swap Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
