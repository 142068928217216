import Dialog from "components/Dialog";
import { Typography, Button, useMediaQuery, IconButton, Stack, Box } from "@mui/material";
import NFTCard from "components/NFTCard";
import { icon_cross_swap, icon_close } from "assets/svg";
import { makeStyles } from "@mui/styles";
import { logAnalytics } from "lib/firebase";

interface Props {
  open: boolean;
  showDepositNFTResult: boolean;
  isTxGoing: boolean;
  nfts: any[];
  registryName: string;
  registryAddress: string;
  onClose: () => void;
  handleDeposit: () => void;
}

const useStyles = makeStyles(() => ({
  dialogSmallTxt: {
    color: "#828282",
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
    lineHeight: "24px",
    fontSize: "16px",
  },
}));

const DepositNFTDialog = function ({
  open,
  showDepositNFTResult,
  isTxGoing,
  nfts,
  registryName,
  registryAddress,
  onClose,
  handleDeposit,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="1034px"
    >
      {!showDepositNFTResult ? (
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
            >
              Deposit{" "}
              <Typography
                component="span"
                sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                {nfts.length} NFTs
              </Typography>{" "}
              to pool
            </Typography>

            <IconButton
              onClick={() => {
                logAnalytics("deposit_dialog_closed");
                onClose();
              }}
            >
              <Box component="img" src={icon_close} />
            </IconButton>
          </Box>
          <Box
            gap="30px"
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            mt="30px"
            pb="20px"
            maxWidth="954px"
            style={{ maxHeight: "820px", overflow: "auto" }}
          >
            {nfts.map((id: number) => (
              <NFTCard
                key={id}
                registryAddress={registryAddress}
                registryName={registryName}
                tokenId={id}
                showSelect={false}
                maxWidth={166}
              />
            ))}
          </Box>
          {!isTxGoing ? (
            <Button
              sx={{ mx: "auto" }}
              variant="contained"
              onClick={() => {
                logAnalytics("deposit_confirmed");
                handleDeposit();
              }}
            >
              Confirm and Deposit
            </Button>
          ) : (
            <Typography sx={{ color: "#2F80ED", textAlign: "center" }}>Processing Transaction...</Typography>
          )}
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                color: "#2F80ED",
              }}
            >
              Your NFTs have been deposited!
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Box component="img" src={icon_close} />
            </IconButton>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default DepositNFTDialog;
