import { useTheme } from "@emotion/react";
import { Box, Link } from "@mui/material";
import { useActiveWeb3React } from "hooks/web3";

const Footer = function () {
  const theme = useTheme();
  const { chainId } = useActiveWeb3React();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      gap="10px"
      sx={{
        lineHeight: "20px",
        color: "rgba(255,255,255,0.33)",
        fontSize: "14px",
        padding: "40px",
      }}
    >
      <Link
        sx={{
          color: "rgba(255,255,255,0.33)",
          textDecoration: "none",
          paddingLeft: "5px",
          paddingRight: "15px",
          "&:hover": {
            color: "white",
          },
        }}
        target="_blank"
        href={`https://${chainId === 1 ? "" : "rinkeby."}etherscan.io/address/${
          process.env.REACT_APP_UGLY_CONTRACT_ADDRESS
        }`}
      >
        Contract
      </Link>
      {/* <Link
        sx={{
          color: "rgba(255,255,255,0.33)",
          textDecoration: "none",
          paddingRight: "15px",
          "&:hover": {
            color: "white",
          },
        }}
        href="https://mustard-labs.io/"
        target="_blank"
      >
        Mustard Labs
      </Link> */}
      <Link
        sx={{
          color: "rgba(255,255,255,0.33)",
          textDecoration: "none",
          paddingLeft: "5px",
          paddingRight: "15px",
          "&:hover": {
            color: "white",
          },
        }}
        target="_blank"
        href="https://twitter.com/Mustard_Labs"
      >
        Twitter
      </Link>
      <Link
        sx={{
          color: "rgba(255,255,255,0.33)",
          textDecoration: "none",
          paddingLeft: "5px",
          paddingRight: "15px",
          "&:hover": {
            color: "white",
          },
        }}
        target="_blank"
        href="https://discord.com/channels/921488765711712347"
      >
        Discord
      </Link>
    </Box>
  );
};

export default Footer;
