import * as React from "react";
import { Box, useTheme } from "@mui/system";
import { Typography, Button, useMediaQuery } from "@mui/material";
import { useActiveWeb3React } from "hooks/web3";
import { UGLYPOOL_CONTRACT_ADDRESS, DAPP_ID, COLLECTION_NAMES } from "config/misc";
import { DEFAULT_CHAIN_ID } from "config/chains";
import abiUglyPool from "abi/uglypool.json";
import { ethers } from "ethers";
import WalletContext from "context/WalletContext";
import { PoolObject, setApproval } from "utils/helper";
import { useParams } from "react-router-dom";
import NFTCard from "components/NFTCard";
import Notify from "bnc-notify";
import * as fb from "lib/firebase";
import { getNFTById } from "hooks/useNFT";

import { icon_swap, icon_tag, icon_cross_swap } from "assets/svg";

import { useState } from "react";
import abiRegular from "abi/regulars.json";
import ApprovalDialog from "components/Dialog/ApprovalDialog";
import NFTCollectionInfoBox from "components/NFTCollectionInfoBox/new";
import SellDialog from "components/Dialog/SellDialog";
import SwapDialog from "components/Dialog/SwapDialog";
import RandomSwapDialog from "components/Dialog/RandomSwapDialog";
import EditTreasuryFundsDialog from "components/Dialog/EditTreasuryFundsDialog";
import EditBuybackPriceDialog from "components/Dialog/EditBuybackPriceDialog";
import EditTradeFeeDialog from "components/Dialog/EditTradeFeeDialog";
import DepositNFTDialog from "components/Dialog/DepositNFTDialog";
import WithdrawNFTDialog from "components/Dialog/WithdrawNFTDialog";
import MyNftsGrid from "pages/Pools/MyNFTsGrid";

import MyNFTPicker from "./MyNFTPicker";
import PoolActivity from "./PoolActivity";

const Pool = function ({ activities, setActivities }: any) {
  const [depositNFTs, setDepositNFTs] = React.useState<number[] | []>([]);
  const [withdrawNFTs, setWithdrawNFTs] = React.useState<number[] | []>([]);
  const [showApprovalDialog, setShowApprovalDialog] = React.useState<number | null>(null);
  const [isApproving, setIsApproving] = useState(false);
  const [pool, setPool] = React.useState<PoolObject | null>(null);
  const [selectedMyNFT, setSelectedMyNFT] = React.useState(-1);
  const [swapPoolNFT, setSwapPoolNFT] = React.useState(-1);
  const [swapMyNFT, setSwapMyNFT] = React.useState(-1);

  const [myNFTToSell, setMyNFTToSell] = React.useState(-1);
  const [registryName, setRegistryName] = React.useState("");
  const [sellNFTs, setSellNFTs] = React.useState("");
  const [notify, setNotify] = React.useState<any>(null);
  const [isTxGoing, setIsTxGoing] = React.useState<boolean>(false);
  const [contractListener, setContractListener] = React.useState<any>(null);

  const [visibleConfirmSellDialog, setVisibleConformSellDialog] = useState<boolean>(false);
  const [showSellResult, setShowSellResult] = useState<boolean>(false);
  const [showSwapResult, setShowSwapResult] = useState<boolean>(false);
  const [showRandomSwapResult, setShowRandomSwapResult] = useState<boolean>(false);
  const [showDepositNFTResult, setShowDepositNFTResult] = useState<boolean>(false);
  const [showWithdrawNFTResult, setShowWithdrawNFTResult] = useState<boolean>(false);
  const [visibleConfirmSwapDialog, setVisibleConformSwapDialog] = useState<boolean>(false);
  const [visibleConfirmRandomSwapDialog, setVisibleConformRandomSwapDialog] = useState<boolean>(false);
  const [visibleConfirmDepositNFTDialog, setVisibleConformDepositNFTDialog] = useState<boolean>(false);
  const [visibleConfirmWithdrawNFTDialog, setVisibleConformWithdrawNFTDialog] = useState<boolean>(false);

  const [showChangeBuybackDialog, setShowChangeBuybackDialog] = useState<boolean>(false);
  const [showChangeTreasuryDialog, setShowChangeTreasuryDialog] = useState<boolean>(false);
  const [showChangeTradeFeeDialog, setShowChangeTradeFeeDialog] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = React.useState("pool");

  const { setWalletBalance } = React.useContext(WalletContext);

  const { library, account, chainId } = useActiveWeb3React();
  const theme = useTheme();
  const provider = !account ? library : library?.getSigner();
  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, provider);
  const isMedium = useMediaQuery(theme.breakpoints.up(720));
  const id = Number(useParams().id);
  const { poolSlug } = useParams();

  const [visibleMyNFTPicker, openMyNFTPicker] = React.useState({ visible: false, action: "sell" });

  const [myNFTs, setMyNFTs] = React.useState([]);

  // Edit dialog refs
  const EditTreasuryFundsDialogRef = React.useRef<any>(null);
  const EditBuybackPriceDialogRef = React.useRef<any>(null);
  const EditTradeFeeDialogRef = React.useRef<any>(null);

  const notifyHash = (hash: any, txType: string) => {
    const { emitter } = notify.hash(hash);

    emitter.on("txSent", console.log);
    emitter.on("txPool", console.log);
    emitter.on("txConfirmed", async () => {
      if (!pool) return;
      try {
        switch (txType) {
          case "depositEth":
            setIsTxGoing(false);
            await fb.addActivity(
              {
                name: "Pool Deposit Eth",
                data: {
                  amount: EditTreasuryFundsDialogRef.current.getData().deposit,
                  registry: pool.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            console.log(pool.treasury + EditTreasuryFundsDialogRef.current.getData().deposit);
            setPool({ ...pool, treasury: pool.treasury + EditTreasuryFundsDialogRef.current.getData().deposit });
            setShowChangeTreasuryDialog(false);
            break;
          case "withdrawEth":
            setIsTxGoing(false);
            await fb.addActivity(
              {
                name: "Pool Withdraw Eth",
                data: {
                  amount: EditTreasuryFundsDialogRef.current.getData().withdraw,
                  registry: pool.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            setPool({ ...pool, treasury: pool.treasury - EditTreasuryFundsDialogRef.current.getData().withdraw });
            setShowChangeTreasuryDialog(false);
            break;
          case "depositNFTs": {
            setIsTxGoing(false);
            setShowDepositNFTResult(true);
            await fb.addActivity(
              {
                name: "Pool Deposit NFTs",
                data: {
                  nfts: depositNFTs,
                  registry: pool.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            const { nfts } = pool;
            depositNFTs.map((id: number) => nfts.push(id));
            setPool({ ...pool, nfts });
            setDepositNFTs([]);
            break;
          }
          case "withdrawNFTs": {
            setIsTxGoing(false);
            setShowWithdrawNFTResult(true);
            await fb.addActivity(
              {
                name: "Pool Withdraw NFTs",
                data: {
                  nfts: withdrawNFTs,
                  registry: pool.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            const { nfts } = pool;
            withdrawNFTs.map((id: number) => nfts.splice(nfts.indexOf(id), 1));
            setPool({ ...pool, nfts });
            setWithdrawNFTs([]);
            break;
          }
          case "approvalForSwap": {
            let tx;
            if (!pool.random) {
              tx = await contract.swapSelectedUgly(pool.id, selectedMyNFT, swapPoolNFT, {
                value: ethers.utils.parseEther(pool.fee.toString()),
              });
            } else {
              tx = await contract.swapRandomUgly(pool.id, selectedMyNFT);
            }
            notifyHash(tx.hash, "swapNFT");
            break;
          }
          case "approvalForSell": {
            const ids: number[] = sellNFTs.split(",").map((id: string) => Number(id));
            const tx = await contract.buyback(pool.id, ids);
            notifyHash(tx.hash, "sellNFTs");
            break;
          }
          case "sellNFTs": {
            setIsTxGoing(false);
            setShowSellResult(true);

            fb.addActivity(
              {
                name: "Buyback",
                data: {
                  nfts: [myNFTToSell],
                  registry: pool?.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );

            const { nfts } = pool;
            const ids: number[] = [selectedMyNFT];
            setPool({ ...pool, nfts: nfts.concat(ids), treasury: pool.treasury - ids.length * pool.price });
            setSellNFTs("");
            break;
          }
          case "setPoolBuybackPrice": {
            setIsTxGoing(false);

            fb.addActivity(
              {
                name: "Set Buyback Price",
                data: {
                  amount: EditBuybackPriceDialogRef.current.getData(),
                  registry: pool?.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            setPool({ ...pool, price: EditBuybackPriceDialogRef.current.getData() });
            setShowChangeBuybackDialog(false);
            break;
          }
          case "setPoolFee": {
            setIsTxGoing(false);

            fb.addActivity(
              {
                name: "Set Pool Fee",
                data: {
                  amount: EditTradeFeeDialogRef.current.getData(),
                  registry: pool?.registry,
                  registryName,
                  owner: account,
                  txHash: hash,
                },
              },
              chainId
            );
            setPool({ ...pool, fee: EditTradeFeeDialogRef.current.getData() });
            setShowChangeTradeFeeDialog(false);
            break;
          }
          default:
            break;
        }
      } catch (e) {
        console.log(e);
        setIsTxGoing(false);
      }
    });
    emitter.on("txSpeedUp", console.log);
    emitter.on("txCancel", console.log);
    emitter.on("txFailed", async (e: any) => {
      if (!pool) return;
      switch (txType) {
        case "depositEth":
          await fb.addActivity(
            {
              name: "Pool Deposit Eth Failed",
              data: {
                amount: EditTreasuryFundsDialogRef.current.getData().deposit,
                registry: pool.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        case "withdrawEth":
          await fb.addActivity(
            {
              name: "Pool Withdraw Eth Failed",
              data: {
                amount: EditTreasuryFundsDialogRef.current.getData().withdraw,
                registry: pool.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        case "depositNFTs": {
          await fb.addActivity(
            {
              name: "Pool Deposit NFTs Failed",
              data: {
                nfts: depositNFTs,
                registry: pool.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        }
        case "withdrawNFTs": {
          await fb.addActivity(
            {
              name: "Pool Withdraw NFTs Failed",
              data: {
                nfts: withdrawNFTs,
                registry: pool.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        }
        case "sellNFTs": {
          fb.addActivity(
            {
              name: "Buyback Failed",
              data: {
                nfts: sellNFTs.split(",").map((id: string) => Number(id)),
                registry: pool?.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        }
        case "setPoolBuybackPrice": {
          fb.addActivity(
            {
              name: "Set Buyback Price Failed",
              data: {
                price: EditBuybackPriceDialogRef.current.getData(),
                registry: pool?.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        }
        case "setPoolFee": {
          fb.addActivity(
            {
              name: "Set Pool Fee Failed",
              data: {
                fee: EditTradeFeeDialogRef.current.getData(),
                registry: pool?.registry,
                registryName,
                owner: account,
                txHash: hash,
              },
            },
            chainId
          );
          break;
        }
        default:
          break;
      }
      console.log(e);
      setIsTxGoing(false);
    });
  };

  const handleWithdrawNFTs = async () => {
    if (!pool) return;
    setIsTxGoing(true);
    try {
      const tx = await contract.withdrawPoolNFTs(pool.id, withdrawNFTs);
      notifyHash(tx.hash, "withdrawNFTs");
    } catch (e) {
      console.log(e);
      setIsTxGoing(false);
    }
  };

  const handleDepositNFTs = async () => {
    if (!pool) return;
    if (!depositNFTs) {
      alert("Please add NFT ids");
      return;
    }
    setIsTxGoing(true);
    try {
      const tx = await contract.depositNFTs(pool.id, depositNFTs, {
        value: 0,
      });
      notifyHash(tx.hash, "depositNFTs");
    } catch (e) {
      setIsTxGoing(false);
      console.log(e);
    }
  };

  const handleSwap = async () => {
    if (!pool) return;
    setIsTxGoing(true);
    try {
      const res = await setApproval(account, library, pool.registry, notifyHash, "approvalForSwap");
      if (!res) {
        let tx;
        if (!pool.random) {
          tx = await contract.swapSelectedUgly(pool.id, swapMyNFT, swapPoolNFT, {
            value: ethers.utils.parseEther(pool.fee.toString()),
          });
        } else {
          tx = await contract.swapRandomUgly(pool.id, swapMyNFT);
        }
        notifyHash(tx.hash, "swapNFT");
      }
    } catch (e) {
      setIsTxGoing(false);
      console.log(e);
    }
  };

  const handleSell = async () => {
    if (!pool) return;
    const ids: number[] = [myNFTToSell];
    if (ids.length * pool.price > pool.treasury) {
      alert("Balance is not enough in pool for selling.");
      return;
    }
    setIsTxGoing(true);
    try {
      const res = await setApproval(account, library, pool.registry, notifyHash, "approvalForSell");
      if (!res) {
        const tx = await contract.buyback(pool.id, ids);
        notifyHash(tx.hash, "sellNFTs");
      }
    } catch (e) {
      setIsTxGoing(false);
      console.log(e);
    }
  };
  React.useEffect(() => {
    if (!pool) {
      return () => {
        contract.removeAllListeners("TradeExecuted");
      };
    }

    if (!contractListener) {
      const listener = contract.on(
        "TradeExecuted",
        (poolId: ethers.BigNumber, user: string, myNFT: ethers.BigNumber, poolNFT: ethers.BigNumber, log: any) => {
          if (user !== account || poolId.toNumber() !== pool.id) return;

          getNFTById(contract, pool.registry, myNFT.toNumber()).then(myNFTObj => {
            getNFTById(contract, pool.registry, poolNFT.toNumber()).then(poolNFTObj => {
              fb.addActivity(
                {
                  name: pool.random ? "Random Swap" : "Selected Swap",
                  data: {
                    myNFT: myNFT.toNumber(),
                    myNFTImage: myNFTObj.imageUrl,
                    poolNFT: poolNFT.toNumber(),
                    poolNFTImage: poolNFTObj.imageUrl,
                    registry: pool.registry,
                    registryName: pool.registryName,
                    owner: account,
                    txHash: log.transactionHash,
                  },
                },
                chainId
              );
            });
          });

          if (pool?.random) {
            setShowRandomSwapResult(true);
            setSwapPoolNFT(poolNFT.toNumber());
          } else {
            setShowSwapResult(true);
          }

          let { nfts } = pool;
          nfts = nfts.filter((nft: number) => nft !== poolNFT.toNumber());
          nfts.push(myNFT.toNumber());

          setIsTxGoing(false);
          setPool({ ...pool, nfts });
          setContractListener(listener);
        }
      );
    }
    return () => {
      contract.removeAllListeners("TradeExecuted");
    };
  }, [pool]);
  React.useEffect(() => {
    if (account && notify === null) {
      const notifyObj = Notify({
        dappId: DAPP_ID,
        networkId: DEFAULT_CHAIN_ID,
        darkMode: true,
        onerror: (error: any) => console.log(`Notify error: ${error.message}`),
      });
      setNotify(notifyObj);
    }

    (async () => {
      if (!id && !poolSlug) return;
      let tmp = id;
      if (!id && poolSlug) {
        tmp = await fb.getPoolBySlug(poolSlug);
      }
      const _pool = await contract.pools(tmp);
      const res = await contract.registryName(_pool.registry);
      setRegistryName(res);
      const nfts = await contract
        .getPoolNFTIds(tmp)
        .then((ids: ethers.BigNumber[]) => ids.map((id: ethers.BigNumber) => id.toNumber()))
        .catch((e: Error) => console.log(e));
      const validTokenId = chainId === 1 ? 44 : 1;
      const firstNFT = await getNFTById(contract, _pool.registry, validTokenId);

      const newValue = {
        ..._pool,
        id: tmp,
        treasury: _pool.treasury / 10 ** 18,
        price: _pool.price / 10 ** 18,
        fee: _pool.fee / 10 ** 18,
        nfts,
        logo: firstNFT.imageUrl,
        registryName: res,
      };
      setPool(newValue);
    })();

    (async () => {
      const balance = account && provider ? await provider.getBalance(ethers.utils.getJsonWalletAddress(account)) : 0;
      setWalletBalance(+ethers.utils.formatEther(balance));
    })();
  }, []);

  const onChooseNFT = async (nftId: any) => {
    if (!pool) return;
    if (nftId === -1) {
      alert("Please select your nft");
      return;
    }
    const signer = library?.getSigner();
    const nftContract = new ethers.Contract(pool.registry, abiRegular, signer);
    const isApproved = await nftContract.isApprovedForAll(account, UGLYPOOL_CONTRACT_ADDRESS);
    if (!isApproved && showApprovalDialog === null) {
      setShowApprovalDialog(nftId);
      return;
    }
    openMyNFTPicker({ ...visibleMyNFTPicker, visible: false });
    if (visibleMyNFTPicker.action === "sell") {
      setMyNFTToSell(nftId);
      setSelectedMyNFT(nftId);
      setVisibleConformSellDialog(true);
    } else if (visibleMyNFTPicker.action === "swap") {
      setSwapMyNFT(nftId);
      setVisibleConformSwapDialog(true);
    } else if (visibleMyNFTPicker.action === "random-swap") {
      setSwapMyNFT(nftId);
      setVisibleConformRandomSwapDialog(true);
    } else if (visibleMyNFTPicker.action === "deposit") {
      setDepositNFTs(nftId);
      setVisibleConformDepositNFTDialog(true);
    } else if (visibleMyNFTPicker.action === "withdraw") {
      setWithdrawNFTs(nftId);
      setVisibleConformWithdrawNFTDialog(true);
    }
  };

  if (!pool || !registryName) return null;
  const getNftPickerHeading = (action: string) => {
    if (action === "sell") {
      return {
        text: "Sell to pool for",
        bold: `${pool.price} Ξ`,
      };
    }
    if (action === "swap") {
      return {
        text: "Swap with pool for",
        bold: `${COLLECTION_NAMES[pool.registry]} #${swapPoolNFT}`,
      };
    }

    if (action === "random-swap") {
      return {
        text: "Swap with pool for",
        bold: "a random pool NFT",
      };
    }

    if (action === "withdraw") {
      return {
        text: "Widthraw NFTs from pool",
      };
    }
    if (action === "deposit") {
      return {
        text: "Deposit NFTs to pool",
      };
    }
    return { text: "heading" };
  };

  const onConfirmWithdrawEth = async () => {
    if (EditTreasuryFundsDialogRef.current) {
      const amountWithdraw = EditTreasuryFundsDialogRef.current.getData().withdraw;
      setIsTxGoing(true);
      try {
        const tx = await contract.withdrawPoolTreasury(pool.id, ethers.utils.parseEther(amountWithdraw.toString()));
        notifyHash(tx.hash, "withdrawEth");
      } catch (e) {
        setIsTxGoing(false);
        console.log(e);
      }
    }
  };
  const onConfirmDepositEth = async () => {
    if (EditTreasuryFundsDialogRef.current) {
      const amountDeposit = EditTreasuryFundsDialogRef.current.getData().deposit;
      setIsTxGoing(true);
      try {
        const tx = await contract.depositToTreasury(pool.id, {
          value: ethers.utils.parseEther(amountDeposit.toString()),
        });
        notifyHash(tx.hash, "depositEth");
      } catch (e) {
        setIsTxGoing(false);
        console.log(e);
      }
    }
  };

  const onConfirmEditBuybackPrice = async () => {
    if (EditBuybackPriceDialogRef.current) {
      const buyback = EditBuybackPriceDialogRef.current.getData();
      setIsTxGoing(true);
      try {
        const tx = await contract.setPoolBuybackPrice(pool.id, ethers.utils.parseEther(buyback.toString()));
        notifyHash(tx.hash, "setPoolBuybackPrice");
      } catch (e) {
        setIsTxGoing(false);
        console.log(e);
      }
    }
  };

  const onConfirmEditTradeFee = async () => {
    if (EditTradeFeeDialogRef.current) {
      const tradeFee = EditTradeFeeDialogRef.current.getData();
      setIsTxGoing(true);
      try {
        const tx = await contract.setPoolFee(pool.id, ethers.utils.parseEther(tradeFee.toString()));
        notifyHash(tx.hash, "setPoolFee");
      } catch (e) {
        setIsTxGoing(false);
        console.log(e);
      }
    }
  };

  const isMyPool = pool.owner.toLowerCase() === (account || "").toLowerCase();

  const withdrawFees = () => {
    contract.withdrawPoolBalance(pool.id);
  };

  const approveContract = async (nftId: number) => {
    if (!pool) return;
    try {
      const signer = library?.getSigner();
      const nftContract = new ethers.Contract(pool.registry, abiRegular, signer);
      const tx = await nftContract.setApprovalForAll(UGLYPOOL_CONTRACT_ADDRESS, true).then((tx: any) => {
        setIsApproving(true);
        return tx.wait();
      });
      if (tx) {
        setShowApprovalDialog(null);
        setIsApproving(false);
        onChooseNFT(nftId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <NFTCollectionInfoBox
        collectionInfo={{ pool, logo: pool.logo, registry: pool.registry, registryName: pool.registryName }}
        isMyPool={isMyPool}
        openMyNFTPicker={openMyNFTPicker}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        swapPoolNFT={swapPoolNFT}
      />
      {/* my pool action bar */}
      <Box textAlign="center" mt="20px">
        {isMyPool && (
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                openMyNFTPicker({ action: "deposit", visible: true });
              }}
            >
              Deposit NFT
            </Button>
            <Button
              variant="contained"
              sx={{ ml: "10px" }}
              onClick={() => {
                openMyNFTPicker({ action: "withdraw", visible: true });
              }}
            >
              Withdraw NFTs
            </Button>
            <Button
              variant="contained"
              sx={{ ml: "10px" }}
              onClick={() => {
                withdrawFees();
              }}
            >
              Withdraw ETH{" "}
              {pool?.balance && pool?.balance?.toString() !== "0"
                ? `(${Math.round(parseFloat(pool?.balance?.toString()) / 10 ** 18)} Ξ)`
                : "(0 Ξ)"}
            </Button>
          </Box>
        )}
      </Box>
      <Box mt="32px">
        <Box gap="20px" display="flex" flexWrap="wrap" mt="30px" pb="10px" justifyContent="center">
          {currentTab === "pool" && (
            <>
              {pool.nfts.map((id: number) => (
                <NFTCard
                  onGrid
                  key={id}
                  registryAddress={pool.registry}
                  registryName={registryName}
                  tokenId={id}
                  selected={swapPoolNFT === id}
                  showSelect={!pool.random && !!account}
                  onSelect={async () => {
                    if (swapPoolNFT === id) {
                      setSwapPoolNFT(-1);
                    } else {
                      setSwapPoolNFT(id);
                    }
                  }}
                />
              ))}
            </>
          )}
          {currentTab === "myRegulars" && (
            <MyNftsGrid contractAddress={pool.registry} myNFTs={myNFTs} setMyNFTs={setMyNFTs} />
          )}
          {currentTab === "activity" && (
            <PoolActivity contractAddress={pool.registry} activities={activities} setActivities={setActivities} />
          )}
        </Box>
      </Box>

      <MyNFTPicker
        visible={visibleMyNFTPicker.visible}
        action={visibleMyNFTPicker.action}
        contractAddress={pool.registry}
        heading={getNftPickerHeading(visibleMyNFTPicker.action)}
        poolNFTs={pool.nfts}
        onClickBackBtn={() => {
          openMyNFTPicker({ ...visibleMyNFTPicker, visible: false });
        }}
        onChooseNFT={onChooseNFT}
      />

      <SellDialog
        open={visibleConfirmSellDialog}
        onClose={() => {
          setVisibleConformSellDialog(false);
          setShowSellResult(false);
        }}
        price={pool.price}
        isTxGoing={isTxGoing}
        nftInfo={{ registry: pool.registry, registryName, tokenId: selectedMyNFT }}
        handleSell={handleSell}
        showSellResult={showSellResult}
      />

      {/* Selected Swap modal */}
      <SwapDialog
        open={visibleConfirmSwapDialog}
        onClose={() => {
          setSelectedMyNFT(0);
          setSwapPoolNFT(0);
          setSwapMyNFT(-1);
          setVisibleConformSwapDialog(false);
          setShowSwapResult(false);
        }}
        isTxGoing={isTxGoing}
        handleSwap={handleSwap}
        buyNFT={{ tokenId: swapPoolNFT, registryName, registry: pool.registry, fee: pool.fee }}
        sellNFT={{ tokenId: swapMyNFT, registryName, registry: pool.registry }}
        showSwapResult={showSwapResult}
      />

      {/* Random Swap modal */}
      <RandomSwapDialog
        open={visibleConfirmRandomSwapDialog}
        onClose={() => {
          setSelectedMyNFT(0);
          setSwapPoolNFT(0);
          setSwapMyNFT(-1);
          setVisibleConformRandomSwapDialog(false);
          setShowRandomSwapResult(false);
        }}
        isTxGoing={isTxGoing}
        handleSwap={handleSwap}
        buyNFT={{ tokenId: swapPoolNFT, registryName, registry: pool.registry, fee: pool.fee }}
        sellNFT={{ tokenId: swapMyNFT, registryName, registry: pool.registry }}
        showRandomSwapResult={showRandomSwapResult}
      />
      {isMyPool && (
        <>
          <EditTreasuryFundsDialog
            ref={EditTreasuryFundsDialogRef}
            open={showChangeTreasuryDialog}
            isTxGoing={isTxGoing}
            treasury={pool.treasury}
            onClose={() => {
              setShowChangeTreasuryDialog(false);
            }}
            onWithdraw={onConfirmWithdrawEth}
            onDeposit={onConfirmDepositEth}
          />
          <EditBuybackPriceDialog
            ref={EditBuybackPriceDialogRef}
            open={showChangeBuybackDialog}
            price={pool.price}
            isTxGoing={isTxGoing}
            onClose={() => {
              setShowChangeBuybackDialog(false);
            }}
            onConfirm={onConfirmEditBuybackPrice}
          />
          <EditTradeFeeDialog
            ref={EditTradeFeeDialogRef}
            open={showChangeTradeFeeDialog}
            fee={pool.fee}
            isTxGoing={isTxGoing}
            onClose={() => {
              setShowChangeTradeFeeDialog(false);
            }}
            onConfirm={onConfirmEditTradeFee}
          />
        </>
      )}

      <DepositNFTDialog
        open={visibleConfirmDepositNFTDialog}
        showDepositNFTResult={showDepositNFTResult}
        isTxGoing={isTxGoing}
        nfts={depositNFTs}
        registryName={registryName}
        registryAddress={pool.registry}
        onClose={() => {
          setDepositNFTs([]);
          setVisibleConformDepositNFTDialog(false);
        }}
        handleDeposit={handleDepositNFTs}
      />

      <WithdrawNFTDialog
        open={visibleConfirmWithdrawNFTDialog}
        showWithdrawNFTResult={showWithdrawNFTResult}
        isTxGoing={isTxGoing}
        nfts={withdrawNFTs}
        registryName={registryName}
        registryAddress={pool.registry}
        onClose={() => {
          setWithdrawNFTs([]);
          setVisibleConformWithdrawNFTDialog(false);
        }}
        handleWithdraw={handleWithdrawNFTs}
      />
      {showApprovalDialog !== null && (
        <ApprovalDialog
          registry={pool.registry}
          onClose={() => {
            setShowApprovalDialog(null);
            setIsApproving(false);
          }}
          onConfirm={() => approveContract(showApprovalDialog)}
          isConfirming={isApproving}
        />
      )}
    </Box>
  );
};
export default Pool;
