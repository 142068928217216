import { Box, Link, Typography, IconButton, useMediaQuery } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { formatWalletAddress } from "components/Menu/WalletMenu";
import { useTheme } from "@mui/system";

interface Props {
  collectionInfo: {
    logo?: string;
    registry: string;
    registryName?: string;
  };
}

const Index = function ({ collectionInfo }: Props) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="img"
        sx={{ width: "100px", height: "100px", borderRadius: "50px" }}
        src={collectionInfo.logo}
        alt={collectionInfo.registryName}
      />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: "30px" }}>
        <Box>
          <Typography component="div" sx={{ fontSize: "24px", lineHeight: "28px", fontWeight: 500 }}>
            {collectionInfo.registryName}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ lineHeight: "24px", fontSize: "16px", color: "#828282", fontWeight: "500" }}>
              {isMedium ? formatWalletAddress(collectionInfo.registry) : collectionInfo.registry}
            </Typography>
            <Link
              href={`https://rinkeby.etherscan.io/address/${collectionInfo.registry}`}
              target="_blank"
              rel="noopener"
            >
              <IconButton>
                <OpenInNew sx={{ color: "#828282", fontSize: "16px" }} />
              </IconButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
