import { useEffect, useMemo, useState } from "react";
import { Box, Container } from "@mui/material";
import { ethers } from "ethers";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { useActiveWeb3React } from "hooks/web3";
import Header from "components/Header";
import CreatePool from "pages/CreatePool";
import Pools, { PoolsArr } from "pages/Pools";
import Home from "pages/Home";
import Pool from "pages/Pools/Pool";
import Activity from "pages/Activity";
import WalletContext from "context/WalletContext";
import Footer from "components/Footer";
import { logAnalytics, app } from "lib/firebase";
import Web3ReactManager from "./components/Web3ReactManager";

const App = function () {
  const { account } = useActiveWeb3React();
  const [activities, setActivities] = useState<any>([]);
  const [pools, setPools] = useState<PoolsArr>({});
  const [withFeatured, setWithFeatured] = useState<number[]>([1, 1, 1]);

  const [walletBalance, setWalletBalance] = useState(0);
  const value = useMemo(() => ({ walletBalance, setWalletBalance, account }), [walletBalance, account]);

  useEffect(() => {
    if (app) {
      console.log(app);
      logAnalytics("site_visited");
    }
  }, [app]);

  return (
    <Web3ReactManager>
      <Container maxWidth="lg" sx={{ paddingInline: "40px" }}>
        <WalletContext.Provider value={value}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ minHeight: "100vh", "& > div:not(:first-child):not(:last-child)": { flexGrow: 1 } }}
            justifyContent="space-between"
          >
            <Header />
            <Routes>
              <Route path="/" element={<Home withFeatured={withFeatured} pools={pools} setPools={setPools} />} />
              <Route path="/pools" element={<Pools pools={pools} setPools={setPools} />} />
              <Route
                path="/manage"
                element={<Pools isMine pools={pools} setPools={setPools} />}
                key={document.location.href}
              />
              <Route
                path="/new"
                element={<Pools isMine pools={pools} setPools={setPools} />}
                key={document.location.href}
              />
              <Route path="/pool/:id" element={<Pool activities={activities} setActivities={setActivities} />} />
              <Route path="/create-pool" element={<CreatePool />} />
              <Route
                path="/activity"
                element={<Activity activities={activities} setActivities={setActivities} currentTab="all" />}
              />
              <Route
                path="/my-activity"
                element={<Activity activities={activities} setActivities={setActivities} currentTab="myActivity" />}
              />
              <Route path="/:poolSlug" element={<Pool />} />
            </Routes>
            <Footer />
          </Box>
        </WalletContext.Provider>
      </Container>
    </Web3ReactManager>
  );
};

export default App;
