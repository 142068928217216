import Dialog from "components/Dialog";
import { Typography, Button, useMediaQuery, IconButton, Stack, Box } from "@mui/material";
import NFTCard from "components/NFTCard";
import { icon_cross_swap, icon_close } from "assets/svg";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

interface Props {
  open: boolean;
  showRandomSwapResult: boolean;
  isTxGoing: boolean;
  buyNFT: {
    fee: number;
    tokenId: number;
    registry: string;
    registryName: string;
  };
  sellNFT: {
    tokenId: number;
    registry: string;
    registryName: string;
  };
  onClose: () => void;
  handleSwap: () => void;
}

const useStyles = makeStyles(() => ({
  dialogSmallTxt: {
    color: "#828282",
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
    lineHeight: "24px",
    fontSize: "16px",
  },
}));

const Index = function ({ open, isTxGoing, showRandomSwapResult, buyNFT, sellNFT, onClose, handleSwap }: Props) {
  const classes = useStyles();

  useEffect(() => {
    if (showRandomSwapResult) {
      setTimeout(() => onClose(), 3000);
    }
  }, [showRandomSwapResult]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {!showRandomSwapResult ? (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
            >
              Swap with pool for <br />
              <Typography
                component="span"
                sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                a random pool NFT
              </Typography>
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Box component="img" src={icon_close} />
            </IconButton>
          </Box>

          <Stack direction="row" justifyContent="center" mb="30px" width="420px">
            <Stack>
              <Typography className={classes.dialogSmallTxt}>Swap with my NFT</Typography>
              <NFTCard
                registryAddress={sellNFT.registry}
                registryName={sellNFT.registryName}
                tokenId={sellNFT.tokenId}
              />
            </Stack>
          </Stack>

          <Typography
            sx={{
              alignItems: "center",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "28px",
              textAlign: "center",
              mb: "30px",
            }}
          >
            Trade fee: &nbsp;
            <Typography
              component="span"
              sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
            >
              {buyNFT.fee} Ξ
            </Typography>
          </Typography>
          {!isTxGoing ? (
            <Button
              startIcon={<Box component="img" src={icon_cross_swap} />}
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => handleSwap()}
            >
              Confirm and Swap Now
            </Button>
          ) : (
            <Typography sx={{ color: "#2F80ED", textAlign: "center" }}>Processing Transaction...</Typography>
          )}
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
            >
              You received &nbsp;
              <Typography
                component="span"
                sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                Regular #{sellNFT.tokenId}
              </Typography>
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Box component="img" src={icon_close} />
            </IconButton>
          </Box>
          <Box sx={{ width: "340px", mx: "auto", display: "flex", justifyContent: "center", mb: "40px" }}>
            <NFTCard
              key={buyNFT.tokenId}
              registryAddress={buyNFT.registry}
              registryName={buyNFT.registryName}
              tokenId={buyNFT.tokenId}
              maxWidth={225}
            />
          </Box>
          <Typography sx={{ color: "#2F80ED", textAlign: "center" }}>Your swap was successful!</Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default Index;
