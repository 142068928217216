import { Theme, Dialog, DialogContent } from "@mui/material";

import { makeStyles } from "@mui/styles";

interface Props {
  children: any;
  open: boolean;
  onClose: () => void;
  maxWidth?: string | undefined;
}
interface StyleProps {
  maxWidth: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dialogPaper: props => ({
    backgroundColor: "black",
    borderRadius: 30,
    maxWidth: props.maxWidth,
    padding: "40px",
    [theme.breakpoints.down(721)]: {
      borderRadius: 0,
      height: "100%",
      width: "100%",
      maxWidth: "100vw",
      maxHeight: "100vh",
      margin: 0,
    },
  }),
  backdrop: {
    backdropFilter: "blur(6px)",
  },
}));

const Index = function ({ children, open, onClose, maxWidth = "800px" }: Props) {
  const styleProps: StyleProps = { maxWidth };
  const classes = useStyles(styleProps);
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialogPaper, root: classes.backdrop }}>
      <DialogContent sx={{ padding: "0px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export default Index;
