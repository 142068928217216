import * as React from "react";
import Box from "@mui/material/Box";
import { useActiveWeb3React } from "hooks/web3";
import { useTheme } from "@mui/system";
import { COLLECTIONS_WITH_FIREBASE_IMAGES, UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "abi/uglypool.json";
import { ethers } from "ethers";
import { getNFTById } from "hooks/useNFT";
import { getCollectionLogo, PoolObject } from "utils/helper";
import { useNavigate } from "react-router-dom";
import { Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "components/Loader";
import CollectionCard from "components/CollectionCard";
import AnimationIcon from "assets/animation.gif";

export interface PoolsArr {
  [key: string]: PoolObject[];
}

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontSize: "18px",
  },
  tableCell: {
    fontSize: "18px",
    fontWeight: 400,
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#2a2a2a",
      "& $arrowRightIcon": {
        color: "#000",
      },
    },
    borderColor: "#BDBDBD",
  },
  arrowRightIcon: {
    fontSize: 14,
    marginLeft: "auto",
    marginRight: 0,
    color: "#BDBDBD",
  },
  ownerENS: {
    "&$active": {
      color: "#2F80ED",
      position: "relative",
      alignItems: "center",
      display: "flex",
      "&::before": {
        left: -10,
        position: "absolute",
        content: '""',
        backgroundColor: "#2F80ED",
        borderRadius: "50%",
        width: 5,
        height: 5,
        display: "block",
      },
    },
  },
  active: {},
}));

const Home = function ({
  isMine = false,
  withFeatured,
  pools,
  setPools,
}: {
  isMine?: boolean;
  withFeatured?: number[];
  pools: PoolsArr;
  setPools: any;
}) {
  const { library, account, chainId } = useActiveWeb3React();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const classes = useStyles();

  React.useEffect(() => {
    setPools({});
    setIsLoading(true);
    const getPools = async () => {
      const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, library);
      let poolIds: number[] = [];
      if (isMine) {
        poolIds = await contract
          .poolIdsByOwner(account)
          .then((ids: ethers.BigNumber[]) =>
            ids.filter((id: ethers.BigNumber) => id.toNumber() > 0).map((id: ethers.BigNumber) => id.toNumber())
          )
          .catch((e: Error) => console.log(e));
      } else {
        const poolCnt = await contract
          .numPools()
          .then((cnt: ethers.BigNumber) => cnt.toNumber())
          .catch((e: Error) => console.log(e));
        poolIds = Array.from({ length: poolCnt - 1 }, (_, i) => i + 1);
      }
      await poolIds
        .filter((id: number) => !withFeatured || withFeatured.indexOf(id) > -1)
        ?.map(async (id: number) => {
          const pool = await contract.pools(id);
          const nfts = contract
            .getPoolNFTIds(id)
            .then((ids: ethers.BigNumber[]) => ids.map((id: ethers.BigNumber) => id.toNumber()))
            .catch((e: Error) => console.log(e));
          const registryName = contract.registryName(pool.registry);
          const validTokenId = chainId === 1 ? 44 : 1;
          let firstNFT: any = getNFTById(contract, pool.registry, validTokenId);
          if (pool.registry && COLLECTIONS_WITH_FIREBASE_IMAGES[pool.registry]) {
            firstNFT = {
              imageUrl: getCollectionLogo(COLLECTIONS_WITH_FIREBASE_IMAGES[pool.registry]),
            };
          }
          const ownerENS = library?.lookupAddress(pool.owner || "");
          await Promise.all([nfts, registryName, firstNFT, ownerENS]).then((val: any) => {
            setPools((prevState: any) => {
              const poolArr = prevState[pool.registry] || [];
              poolArr.push({
                ...pool,
                id,
                treasury: pool.treasury / 10 ** 18,
                price: pool.price / 10 ** 18,
                fee: pool.fee / 10 ** 18,
                nfts: val[0],
                registryName: val[1],
                logo: val[2].imageUrl,
                ownerENS: val[3] || pool.owner || "",
              });
              return {
                ...prevState,
                [pool.registry]: poolArr,
              };
            });
          });
        });
      setIsLoading(false);
    };
    getPools();
  }, [isMine, account, chainId, library, setPools, withFeatured]);

  const formatString = (value: number, pool: any) => {
    if (value === 0) {
      return <Typography sx={{ color: "#333333" }}>-</Typography>;
    }
    return value;
  };
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));
  const isSm = useMediaQuery(theme.breakpoints.down(522));

  return (
    <Box style={{ flexGrow: "initial" }}>
      {isLoading && <Loader text="Loading pools..." />}
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="33px">
        <img src={AnimationIcon} alt="uglypool" width="200px" />
        <div>
          <Typography
            className="tralala"
            style={{
              fontWeight: 700,
              fontSize: "29px",
              letterSpacing: ".5px",
              marginBottom: "10px",
              lineHeight: "34px",
            }}
          >
            Welcome to UglyPool.xyz
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "29px",
              marginBottom: "10px",
              lineHeight: "32px",
              letterSpacing: ".5px",
            }}
          >
            Trade your NFTs easily.
          </Typography>
          <Typography>
            Swap in the collection, 1-for-1.
            <br />
            No ERC20 complexities.
            <br />
            Low/No Fees!
          </Typography>
        </div>
      </Box>
      <Box display="flex" flexWrap="wrap" gap="24px" justifyContent="center" mt="50px">
        <Typography
          style={{ fontWeight: 500, fontSize: "24px", width: "100%", textAlign: "center", paddingBottom: "12px" }}
        >
          Featured Pools
        </Typography>
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard
              registryName={pools[key][0].registryName}
              nNFTs={pools[key][0].nfts.length}
              onClick={() => navigate(`pool/${pools[key][0].id}`)}
              fee={pools[key][0].fee}
            />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard registryName="Pickle People" nNFTs={35} onClick={() => navigate(`pool/8`)} fee={0} />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard registryName="DankBots" nNFTs={40} onClick={() => navigate(`pool/4`)} fee={0} />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard
              registryName="Kumite - Genesis Collection"
              nNFTs={44}
              onClick={() => navigate(`pool/5`)}
              fee={0}
            />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard registryName="Grid Gang" nNFTs={30} onClick={() => navigate(`pool/6`)} fee={0} />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard registryName="ForeverBots" nNFTs={40} onClick={() => navigate(`pool/7`)} fee={0} />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard
              registryName="Commoners by Commonopoly"
              nNFTs={40}
              onClick={() => navigate(`pool/9`)}
              fee={0}
            />
          </Box>
        ))}
        {Object.keys(pools).map((key: string, idx: number) => (
          <Box key={idx} mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard registryName="Flawless Renegades" nNFTs={20} onClick={() => navigate(`pool/10`)} fee={0} />
          </Box>
        ))}
        {Object.keys(pools).length === 0 && (
          <Box mb="30px" style={{ width: isSm ? "100%" : "227px", maxWidth: "300px" }}>
            <CollectionCard placeholder registryName="Collection" nNFTs={0} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Home;
