import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCellHeader from "components/TableCellHeader";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { classNames } from "utils";
import { Dispatch, SetStateAction } from "react";

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontSize: "18px",
  },
  tableCell: {
    fontSize: "18px",
    fontWeight: 400,
  },
  row: {
    "&:hover": {
      backgroundColor: "#2a2a2a",
    },
    cursor: "pointer",
    borderColor: "#BDBDBD",
  },
  ownerENS: {
    "&$active": {
      color: "#2F80ED",
      position: "relative",
      alignItems: "center",
      display: "flex",
      "&::before": {
        left: -10,
        position: "absolute",
        content: '""',
        backgroundColor: "#2F80ED",
        borderRadius: "50%",
        width: 5,
        height: 5,
        display: "block",
      },
    },
  },
  dialogSmallTxt: {
    color: "#828282",
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
    lineHeight: "24px",
    fontSize: "16px",
  },
  active: {},
}));

interface Props {
  account?: string;
  pool: {
    id: number;
    owner: string;
    nfts: any[];
    treasury: number;
    price: number;
    fee: number;
    random: boolean;
  };
  setShowChangeBuybackDialog?: Dispatch<SetStateAction<boolean>>;
  setShowChangeTreasuryDialog?: Dispatch<SetStateAction<boolean>>;
  setShowChangeTradeFeeDialog?: Dispatch<SetStateAction<boolean>>;
}

const Index = function ({
  account,
  pool,
  setShowChangeBuybackDialog,
  setShowChangeTreasuryDialog,
  setShowChangeTradeFeeDialog,
}: Props) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>Owner</TableCell>
            <TableCell className={classes.tableHeaderCell} align="center">
              NFTs
            </TableCell>
            {/* <TableCellHeader label="Buyback(eth)" description="Buyback(eth)" />
            <TableCellHeader
              label="Treasury(eth)"
              description="Treasury indicates the amount of eth currently available in the pool for NFT buybacks"
            /> */}
            <TableCellHeader label="Trade Fee" description="Trade Fee" />
            {/* <TableCellHeader label="Swap Type" description="Swap Type" /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={pool.id} className={classes.row}>
            <TableCell className={classes.tableCell}>
              <Typography
                component="div"
                className={classNames(
                  account?.toString().toLocaleLowerCase() === pool.owner?.toString().toLocaleLowerCase()
                    ? classes.active
                    : "",
                  classes.ownerENS
                )}
              >
                {pool.owner &&
                  `${pool.owner.slice(0, 8)}...${pool.owner.slice(pool.owner.length - 6, pool.owner.length)}`}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {pool.nfts?.length}
            </TableCell>
            {/* <TableCell className={classes.tableCell} align="center">
              {account?.toString().toLocaleLowerCase() !== pool.owner?.toString().toLocaleLowerCase() ? (
                `${pool.price} Ξ`
              ) : (
                <Typography
                  sx={{ color: "#2F80ED", cursor: "pointer" }}
                  onClick={() => {
                    if (setShowChangeBuybackDialog) setShowChangeBuybackDialog(true);
                  }}
                >
                  ${pool.price} Ξ
                </Typography>
              )}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <Typography
                component="div"
                sx={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center" }}
              >
                {account?.toString().toLocaleLowerCase() !== pool.owner?.toString().toLocaleLowerCase() ? (
                  `${pool.treasury} Ξ  `
                ) : (
                  <Typography
                    sx={{ color: "#2F80ED", cursor: "pointer" }}
                    onClick={() => {
                      if (setShowChangeTreasuryDialog) setShowChangeTreasuryDialog(true);
                    }}
                  >
                    ${pool.treasury} Ξ&nbsp;&nbsp;
                  </Typography>
                )}
                {pool.treasury > pool.price ? (
                  <CheckCircleIcon sx={{ color: "#27AE60" }} />
                ) : (
                  <CancelIcon sx={{ color: "#EB5757" }} />
                )}
              </Typography>
            </TableCell> */}
            <TableCell className={classes.tableCell} align="center">
              {account?.toString().toLocaleLowerCase() !== pool.owner?.toString().toLocaleLowerCase() ? (
                `${pool.fee} Ξ`
              ) : (
                <Typography
                  sx={{ color: "#2F80ED", cursor: "pointer" }}
                  onClick={() => {
                    if (setShowChangeTradeFeeDialog) setShowChangeTradeFeeDialog(true);
                  }}
                >
                  ${pool.fee} Ξ
                </Typography>
              )}
            </TableCell>
            {/* <TableCell className={classes.tableCell} sx={{ display: "flex", alignItems: "center" }} align="center">
              {pool.random ? "Random" : "Selectable"}
            </TableCell> */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Index;
