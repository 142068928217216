import { initializeApp } from "firebase/app";
import {
  collection,
  setDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  serverTimestamp,
  limit,
} from "@firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD-l3mfvvFo-nk9IXopQEfkVAoly9vbMDA",
  authDomain: "uglypool.firebaseapp.com",
  projectId: "uglypool",
  storageBucket: "uglypool.appspot.com",
  messagingSenderId: "629332441356",
  appId: "1:629332441356:web:74befb81915ae2ef1a7129",
  measurementId: "G-C46JER4CBW",
};

export const app = initializeApp(firebaseConfig, "uglypool");
const db = getFirestore(app);

export const logAnalytics = (event: string) => {
  const analytics = getAnalytics(app);
  console.log(`Event logged: ${event}`);
  logEvent(analytics, event);
};

export async function getActivities(chainId: number | undefined) {
  const activitiesRef = collection(db, chainId === 4 ? "activities_rinkeby" : "activities");
  const q = await query(activitiesRef, where("name", "==", "Selected Swap"), orderBy("createdAt", "desc"), limit(50));
  const acitivtySnapshot = await getDocs(q);
  return acitivtySnapshot.docs.map((doc: any) => doc.data());
}

export async function getActivitiesByCollection(chainId: number | undefined, contractAddress: string) {
  const activitiesRef = collection(db, chainId === 4 ? "activities_rinkeby" : "activities");
  const q = await query(
    activitiesRef,
    where("name", "==", "Selected Swap"),
    where("data.registry", "==", contractAddress),
    orderBy("createdAt", "desc"),
    limit(50)
  );
  const acitivtySnapshot = await getDocs(q);
  return acitivtySnapshot.docs.map((doc: any) => doc.data());
}

export async function addActivity(document: any, chainId: number | undefined) {
  let activityRef;
  const activitiesRef = chainId === 4 ? "activities_rinkeby" : "activities";
  try {
    activityRef = await setDoc(doc(db, activitiesRef, document.data.txHash), {
      ...document,
      createdAt: serverTimestamp(),
    });
  } catch (e) {
    console.log(e);
  }
  return activityRef;
}

export const getPoolBySlug = async (slug: string) => {
  const docRef = doc(db, "shortUrls", slug);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().poolId;
  }
  return null;
};
