import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Popover, TableCell, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles(() => ({
  tableHeaderCell: {},
  label: {
    fontSize: "18px",
    position: "relative",
  },
  popoverpaper: {
    color: "#fff",
    backgroundColor: "rgba(115, 115, 115, 0.7)",
    maxWidth: 300,
    borderRadius: 10,
    pointerEvents: "auto",
  },
  popoverpaperwrapper: {
    position: "relative",
    backdropFilter: "blur(2px)",
    padding: "20px",
  },
  rippleRoot: {
    backgroundColor: "#414141",
    opacity: 0.7,
  },
  iconBtn: {
    position: "absolute",
    right: -12,
    top: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  label: string;
  description: string;
}

const Index = function ({ label, description }: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popoverLeave = () => {
    setAnchorEl(null);
  };

  const popoverEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <TableCell className={classes.tableHeaderCell} align="center">
      <Typography className={classes.label} component="span">
        {label}
        <IconButton
          className={classes.iconBtn}
          aria-describedby={id}
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <InfoIcon sx={{ fontSize: "12px", color: "#BDBDBD" }} />
        </IconButton>
      </Typography>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={popoverLeave}
          sx={{ mt: 1, pointerEvents: "none" }}
          classes={{
            paper: classes.popoverpaper,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box className={classes.popoverpaperwrapper}>{description}</Box>
        </Popover>
      )}
    </TableCell>
  );
};

export default Index;
