import { Box, Stepper, Step, StepLabel, StepIconProps, Typography } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";

import { styled } from "@mui/material/styles";

interface Props {
  activeStep: number;
  steps: any[];
}

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2F80ED",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2F80ED",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#828282",
    borderTopWidth: "1.5px",
    // borderRadius: 1,
    marginleft: "20px",
  },
}));

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  display: "flex",
  width: 26,
  height: 26,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  color: "#828282",
  border: "solid 2px #828282",
  ...(ownerState.active && {
    color: "#2F80ED",
    borderColor: "#2F80ED",
    backgroundColor: "#2F80ED",
  }),
  "& .StepIcon-completedIcon": {
    color: "#F2F2F2",
    backgroundColor: "#2F80ED",
    position: "absolute",
    zIndex: 1,
    fontSize: 18,
    display: "flex",
    width: 26,
    height: 26,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .StepIcon-circle": {
    display: "flex",
    width: 22,
    height: 22,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
  },
}));

const StepLabelRoot = styled(StepLabel)(() => ({
  "& .Mui-completed": {
    color: "#2F80ED !important",
    fontSize: "18px",
  },
  "& .Mui-active": {
    color: "#2F80ED",
    fontSize: "18px",
  },
  "& .Mui-disabled": {
    color: "#828282",
    fontSize: "18px",
  },
}));

const StepIcon = function (props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      <Typography component="span" className={completed ? "StepIcon-completedIcon" : "StepIcon-circle"}>
        {String(icon)}
      </Typography>
    </StepIconRoot>
  );
};

const Index = function ({ activeStep, steps }: Props) {
  return (
    <Stepper activeStep={activeStep} sx={{ mt: "30px", mx: "-20px" }} connector={<Connector />}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        return (
          <Step key={index} {...stepProps} sx={{ px: "20px" }}>
            <StepLabelRoot StepIconComponent={StepIcon}>{label}</StepLabelRoot>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default Index;
