export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const NetworkContextName = "NETWORK";
export const IS_IN_IFRAME = window.parent !== window;
export const UGLYPOOL_CONTRACT_ADDRESS = process.env.REACT_APP_UGLY_CONTRACT_ADDRESS || "";
export const DAPP_ID = process.env.REACT_APP_DAPP_ID;
export const COLLECTIONS_WITH_FIREBASE_IMAGES: any = {
  "0x6d0de90CDc47047982238fcF69944555D27Ecb25": "regulars",
  "0x6d0de90cdc47047982238fcf69944555d27ecb25": "regulars",
  "0x33cbb1e55a4f996ba665c6b907def21b26d7b104": "thegridgang",
  "0x3EAD1A2aDfE8A30f5314054d9fb3EAe136e144a5": "thegridgang",
  "0x3ead1a2adfe8a30f5314054d9fb3eae136e144a5": "thegridgang",
  "0x6661c87764adf7fffa3c7922fa6edfa2fd62ccfc": "commoners",
  "0x495f947276749ce646f68ac8c248420045cb7b5e": "flawlessrenegades",
};
export const COLLECTION_NAMES: any = {
  "0x6d0de90CDc47047982238fcF69944555D27Ecb25": "Regular",
  "0x6d0de90cdc47047982238fcf69944555d27ecb25": "regulars",
  "0x33cbb1e55a4f996ba665c6b907def21b26d7b104": "Grid Gang",
  "0x3EAD1A2aDfE8A30f5314054d9fb3EAe136e144a5": "Grid Gang",
  "0x3ead1a2adfe8a30f5314054d9fb3eae136e144a5": "Grid Gang",
  "0x1821D56D2f3BC5a5ABA6420676A4bbCBCCb2F7fd": "DankBot",
  "0x1821d56d2f3bc5a5aba6420676a4bbcbccb2f7fd": "DankBot",
  "0x6661c87764adf7fffa3c7922fa6edfa2fd62ccfc": "Commoners by Commonopoly",
  "0x495f947276749ce646f68ac8c248420045cb7b5e": "Flawless Renegades",
};

export const openSeaUrls: any = {
  "0x6661c87764adf7fffa3c7922fa6edfa2fd62ccfc": "commoners-by-commonopoly",
  "0xaae22935ab089ca7cbae330eed96dbef8dbc900a": "kumite-genesis-collection",
  "0x3ead1a2adfe8a30f5314054d9fb3eae136e144a5": "the-grid-gang",
  "0x495f947276749ce646f68ac8c248420045cb7b5e": "flawless-renegades",
};
