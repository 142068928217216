import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import * as React from "react";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cancel: true;
  }
}
// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1258,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          padding: "10px 24px",
          borderRadius: "100px",
          fontWeight: 500,
          textTransform: "none",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#F2F2F2",
            backgroundColor: "primary.main",
            display: "inline-flex",
            alignItems: "center",
            letterSpacing: "0.1px",
            fontSize: "14px",
            lineHeight: "20px",
            borderRadius: "20px",
            padding: "10px 24px",
            height: "40px",
            ":hover": {
              backgroundColor: "primary.main",
            },
            ":disabled": {
              backgroundColor: "primary.light",
              opacity: 0.5,
              color: "#F2F2F2",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: "#2F80ED",
            backgroundColor: "#F2F2F2",
            display: "inline-flex",
            alignItems: "center",
            letterSpacing: "0.1px",
            fontSize: "14px",
            lineHeight: "20px",
            borderRadius: "20px",
            padding: "10px 24px",
            height: "40px",
            ":hover": {
              backgroundColor: "#F2F2F2",
            },
            ":disabled": {
              backgroundColor: "#F2F2F2",
              opacity: 0.5,
              color: "#F2F2F2",
            },
          },
        },
      ],
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#EB5757",
      light: "#EB5757",
      dark: "#b14040",
    },
    secondary: {
      main: "#00c86f",
    },
    background: {
      default: "#000000",
      paper: "#161616",
    },
    text: {
      primary: "#e2e2e6",
    },
  },
  typography: {
    fontFamily: "Inter",
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "20px",
    },
  },
});
export default theme;
