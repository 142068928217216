import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useActiveWeb3React } from "hooks/web3";

import Button from "@mui/material/Button";
import { classNames } from "utils";
import Logo from "assets/logo.png";

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    borderRadius: "26px",
    padding: "14px 39px",
    fontSize: "18px",
    color: "white",
    fontWeight: 400,
    textTransform: "none",
    marginRight: "23px",
  },
  active: {
    color: "black",
    fontWeight: 500,
    background: "white",
    "&:hover": {
      color: "black",
      fontWeight: 500,
      background: "white",
    },
  },
}));

const Header = function () {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMedium = useMediaQuery(theme.breakpoints.down(751));

  const location = useLocation();
  const pathName = location.pathname;

  const classes = useStyles();

  return (
    <Box sx={{ mt: "35px", mb: "35px", height: "56px", maxWidth: "1206px" }} display="flex">
      <Box
        sx={{ flexGrow: 1, cursor: "pointer" }}
        justifyContent="left"
        display="flex"
        alignItems="center"
        onClick={() => navigate("/")}
      >
        <Box sx={{ fontWeight: "500", fontSize: "40px", lineHeight: "47px", color: "#2F80ED" }}>
          <img src={Logo} alt="Uglypool.xyz" style={{ height: "57px" }} />
        </Box>
      </Box>
      {!isMedium && (
        <>
          <Box>
            <Button
              className={classNames(pathName === "/" ? classes.active : "", classes.menuButton)}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
          </Box>
          <Box>
            <Button
              className={classNames(pathName === "/activity" ? classes.active : "", classes.menuButton)}
              style={{
                paddingInline: "28px",
              }}
              onClick={() => navigate("/activity")}
            >
              Activity
            </Button>
          </Box>
        </>
      )}

      <Box sx={{ zIndex: 2, textAlign: "right" }}>
        <ConnectWalletButton />
      </Box>
    </Box>
  );
};

export default Header;
