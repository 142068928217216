import { useEffect, useState } from "react";
import { Container, Box, useMediaQuery, Button, Typography, IconButton } from "@mui/material";
import { ethers } from "ethers";
import { useActiveWeb3React } from "hooks/web3";
import { useTheme } from "@mui/system";
import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "abi/uglypool.json";
import NFTCard from "components/NFTCard";
import { allNFTsByWallet } from "utils/helper";

const MyNFTsGrid = function ({ contractAddress, myNFTs, setMyNFTs }: any) {
  const [registryName, setRegistryName] = useState("");
  const theme = useTheme();

  const { library, account } = useActiveWeb3React();
  const signer = library?.getSigner();
  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, signer);

  const getNFTs = async function (account: string, contractAddress: string) {
    const NFTs = await allNFTsByWallet(account, contractAddress);
    return NFTs || [];
  };

  const loadMyNFT = async (account: string, contractAddress: string) => {
    if (!contractAddress) {
      alert("Please input collection address");
      return;
    }
    const res = await contract.registryName(contractAddress);
    setRegistryName(res);
    const tokens = await getNFTs(account, contractAddress);
    setMyNFTs(tokens);
  };

  useEffect(() => {
    if (account) {
      loadMyNFT(account, contractAddress);
    }
  }, [account, contractAddress]);

  return (
    <Box display="flex" flexWrap="wrap" gap="20px" sx={{ pointerEvents: "none", justifyContent: "center" }}>
      {myNFTs.map((item: any) => (
        <NFTCard
          key={item}
          registryAddress={contractAddress}
          registryName={registryName}
          tokenId={item}
          maxWidth={225}
          showSelect
          selected={false}
          onSelect={() => null}
          unselectable
          onGrid
        />
      ))}
    </Box>
  );
};

export default MyNFTsGrid;
