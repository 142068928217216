import icon_arrow_left_svg from "assets/svg/icon_arrow_left.svg";
import icon_swap_svg from "assets/svg/icon_swap.svg";
import icon_tag_svg from "assets/svg/icon_tag.svg";
import icon_close_svg from "assets/svg/icon_close.svg";
import icon_cross_swap_svg from "assets/svg/icon_cross_swap.svg";

export const icon_arrow_left = icon_arrow_left_svg;
export const icon_swap = icon_swap_svg;
export const icon_tag = icon_tag_svg;
export const icon_close = icon_close_svg;
export const icon_cross_swap = icon_cross_swap_svg;
