import { Card, CardContent, CardMedia, Typography, Button, useMediaQuery, Skeleton } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { getCollectionLogo } from "utils/helper";

const CollectionCard = function ({ registryName, onClick, maxWidth = 227, nNFTs, placeholder, fee }: any) {
  const collectionImage = getCollectionLogo(registryName || "");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(522));

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: isSm ? 300 : maxWidth,
        borderRadius: "20px",
        opacity: 0.9,
        "&:hover": {
          opacity: 1,
        },
        boxShadow: "none",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {placeholder && (
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: isSm ? 300 : maxWidth, height: isSm ? 300 : maxWidth }}
        />
      )}
      {!placeholder && (
        <CardMedia
          component="img"
          image={collectionImage}
          alt={registryName}
          sx={{
            width: isSm ? 300 : maxWidth,
            height: isSm ? 300 : maxWidth,
            maxWidth: isSm ? 300 : maxWidth,
            maxHeight: isSm ? 300 : maxWidth,
          }}
        />
      )}

      <CardContent
        sx={{
          display: "flex",
          flexWrap: "wrap",
          padding: "3px 12px",
          paddingBottom: "2px !important",
          alignItems: "center",
          boxShadow: "none",
          gap: "20px",
          backgroundColor: "#161616",
        }}
      >
        <Box sx={{ flexGrow: 1, justifyContent: "center" }} py="8px">
          <Box
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
              color: "white",
              textAlign: "center",
            }}
          >
            {registryName}
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
              color: "rgba(244,239,244,0.33)",
              textAlign: "center",
            }}
          >
            {nNFTs} NFTs • {fee ? `${fee} Ξ Fee` : "No fee"}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CollectionCard;
