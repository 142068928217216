import React, { useImperativeHandle, useEffect, useState, useCallback } from "react";
import Dialog from "components/Dialog";
import { Typography, Button, IconButton, Stack, Box, InputAdornment } from "@mui/material";
import GrayTextInput from "components/GrayTextInput";
import { icon_close } from "assets/svg";
import WalletContext from "context/WalletContext";

interface Props {
  open: boolean;
  treasury: number;
  isTxGoing: boolean;
  onClose: () => void;
  onDeposit?: () => void;
  onWithdraw?: () => void;
}
const Index = React.forwardRef(({ open, treasury, isTxGoing, onClose, onWithdraw, onDeposit }: Props, ref) => {
  const [amountWithdraw, setAmountWithdraw] = useState(0);
  const [amountDeposit, setAmountDeposit] = useState(0);
  const [errorWithdrawMessage, setErrorWithdrawMessage] = useState<string>();
  const [errorDepositMessage, setErrorDepositMessage] = useState<string>();
  const { walletBalance } = React.useContext(WalletContext);

  useImperativeHandle(
    ref,
    () => ({
      getData: () => ({
        withdraw: amountWithdraw,
        deposit: amountDeposit,
      }),
    }),
    [amountWithdraw, amountDeposit]
  );

  useEffect(() => {
    if (amountWithdraw > treasury) {
      setErrorWithdrawMessage("Insufficient treasury balance");
    } else {
      setErrorWithdrawMessage(undefined);
    }

    if (amountDeposit > walletBalance) {
      setErrorDepositMessage("Insufficient wallet balance");
    } else {
      setErrorDepositMessage(undefined);
    }
  }, [amountWithdraw, walletBalance, amountDeposit]);

  let maxBtnStyle: any = {
    width: "40px",
    height: "28px",
    minWidth: "unset",
    bgcolor: "#2F80ED",
    color: "#F2F2F2",
    border: "solid 1px #2F80ED",
    "&:hover": { bgcolor: "#2F80ED", color: "#F2F2F2" },
  };

  if (errorWithdrawMessage) {
    maxBtnStyle = { ...maxBtnStyle, bgcolor: "#F2F2F2", color: "#2F80ED", borderColor: "#2F80ED" };
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "720px", mb: "20px" }}>
        <Typography
          sx={{
            alignItems: "center",
            color: "#333333",
            fontWeight: "500",
            fontSize: "30px",
            lineHeight: "35px",
          }}
        >
          Edit Treasury Funds
        </Typography>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <Box component="img" src={icon_close} />
        </IconButton>
      </Box>
      <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#2F80ED" }}>
        This is the total ETH liquidity in your pool that will buyback NFTs sold to your pool.
        <br />
        You can deposit any amount or withdraw the entire fund at any time, with gas.
      </Typography>
      <Box sx={{ mt: "20px" }}>
        <Stack direction="row" alignItems="center" gap="30px">
          <Box sx={{ width: "280px" }}>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", lineHeight: "21px", textAlign: "center" }}>
              Amount to Withdraw (Ξ)
            </Typography>
            <GrayTextInput
              sx={{ mt: "8px", width: "100%" }}
              value={amountWithdraw}
              type="number"
              onChange={(e: any) => {
                setAmountWithdraw(+e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Button sx={maxBtnStyle} onClick={() => setAmountWithdraw(treasury)}>
                    Max
                  </Button>
                </InputAdornment>
              }
            />
          </Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "700", lineHeight: "21px", mt: "29px" }}>
            Available treasury balance &nbsp;
            <Typography component="span" sx={{ color: "#2F80ED" }}>
              {treasury} Ξ
            </Typography>
          </Typography>
          {!isTxGoing ? (
            <Button
              variant="contained"
              sx={{ mr: 0, ml: "auto", mt: "29px" }}
              disabled={!!errorWithdrawMessage || !amountWithdraw}
              onClick={() => {
                if (onWithdraw) onWithdraw();
              }}
            >
              Withdraw
            </Button>
          ) : (
            <Typography sx={{ color: "#2F80ED", mr: 0, ml: "auto", mt: "29px" }}>Processing Transaction...</Typography>
          )}
        </Stack>
        <Typography sx={{ color: "#EB5757", fontSize: "16px", my: "20px" }}>{errorWithdrawMessage}</Typography>

        <Stack direction="row" alignItems="center" gap="30px" sx={{ mt: "20px" }}>
          <Box sx={{ width: "280px" }}>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", lineHeight: "21px", textAlign: "center" }}>
              Amount to deposit (Ξ)
            </Typography>
            <GrayTextInput
              sx={{ mt: "8px", width: "100%" }}
              value={amountDeposit}
              type="number"
              onChange={(e: any) => {
                setAmountDeposit(+e.target.value);
              }}
            />
          </Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "700", lineHeight: "21px", mt: "29px" }}>
            Available wallet balance &nbsp;
            <Typography component="span" sx={{ color: "#2F80ED" }}>
              {walletBalance.toFixed(3)} Ξ
            </Typography>
          </Typography>
          {!isTxGoing ? (
            <Button
              variant="contained"
              sx={{ mr: 0, ml: "auto", mt: "29px" }}
              disabled={!!errorDepositMessage || !amountDeposit}
              onClick={() => {
                if (onDeposit) onDeposit();
              }}
            >
              Deposit
            </Button>
          ) : (
            <Typography sx={{ color: "#2F80ED", mr: 0, ml: "auto", mt: "29px" }}>Processing Transaction...</Typography>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
});

export default Index;
