import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import { ethers } from "ethers";
import CryptoJS from "crypto-js";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import abiRegular from "abi/regulars.json";

export type PoolObject = {
  id: number;
  registry: string;
  registryName?: string;
  logo?: string;
  owner: string;
  ownerENS?: string;
  price: number;
  treasury: number;
  random: boolean;
  nfts: any[];
  fee: number;
  balance?: any;
};
export async function setApproval(account: any, library: any, registry: any, notifyHash: any, txType: string) {
  const signer = library?.getSigner();
  const nftContract = new ethers.Contract(registry, abiRegular, signer);
  const isApproved = await nftContract.isApprovedForAll(account, UGLYPOOL_CONTRACT_ADDRESS);
  if (!isApproved) {
    alert("You must approve uglypool contract from NFT contract first");
    try {
      const tx = await nftContract.setApprovalForAll(UGLYPOOL_CONTRACT_ADDRESS, true);
      notifyHash(tx.hash, txType);
      return 1;
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
}

export const getCollectionLogo = (collectionSlug: string) =>
  `https://firebasestorage.googleapis.com/v0/b/nft-collections-2dc2c.appspot.com/o/collectionLogos%2F${collectionSlug.toLowerCase()}.png?alt=media`;

export const getNFTFirebaseImage = (collectionSlug: string, tokenId: number) => {
  if (collectionSlug === "regulars") {
    return `https://firebasestorage.googleapis.com/v0/b/nft-collections-2dc2c.appspot.com/o/regulars%2F${tokenId}?alt=media`;
  }
  if (collectionSlug === "thegridgang") {
    return getNFTFirebaseImgHashed(collectionSlug, tokenId);
  }
  return `https://firebasestorage.googleapis.com/v0/b/nft-collections-2dc2c.appspot.com/o/${collectionSlug}%2F${tokenId}.png?alt=media`;
};

export const getNFTFirebaseImgHashed = (collectionSlug: string, tokenId: number) => {
  if (collectionSlug === "thegridgang") {
    const text = collectionSlug + tokenId;
    const hash = CryptoJS.MD5(text).toString().slice(5, 20);
    const file = `${hash + tokenId}.gif`;
    return `https://firebasestorage.googleapis.com/v0/b/nft-collections-2dc2c.appspot.com/o/${collectionSlug}%2F${file}?alt=media`;
  }
  return "";
};

const alcheUrl =
  process.env.REACT_APP_UGLY_CONTRACT_ADDRESS?.toLocaleLowerCase() ===
  "0xc2760aEd8BD6dd45676Ca565Cd5eAcbB2803987a".toLocaleLowerCase()
    ? "https://eth-mainnet.g.alchemy.com/v2/C3TQE3JEcky_r8zfz0y08_YQShTY4y7P"
    : "https://eth-rinkeby.alchemyapi.io/v2/ml82PmYrEPF4I2RSjqQcgHht1mR6OOy4";
// Using HTTPS
const web3 = createAlchemyWeb3(alcheUrl);
export const allNFTsByWallet = async (account: string, contractAddress: string) => {
  const nftsForOwner =
    (await web3.alchemy.getNfts({ owner: account, contractAddresses: [contractAddress] })).ownedNfts || [];
  const nftIds = nftsForOwner.map((item: any) => {
    const number = ethers.BigNumber.from(item.id.tokenId).toNumber();
    return number;
  });
  return nftIds;
};
