import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as fb from "lib/firebase";
import { Box, Icon, Avatar } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import moment from "moment";
import { useActiveWeb3React } from "hooks/web3";
import { formatWalletAddress } from "components/Menu/WalletMenu";
import { COLLECTIONS_WITH_FIREBASE_IMAGES } from "config/misc";
import { getNFTFirebaseImage } from "utils/helper";

const activityIcons: any = {
  "Random Swap": ShuffleIcon,
  "Selected Swap": SwapHorizIcon,
  Buyback: LocalOfferIcon,
};

const PoolActivity = function ({ activities, setActivities, contractAddress }: any) {
  const { account, chainId } = useActiveWeb3React();

  React.useEffect(() => {
    (async () => {
      setActivities(await fb.getActivitiesByCollection(chainId, contractAddress));
    })();
  }, [account, chainId, setActivities]);

  return (
    <Box width="100%">
      {(!activities ||
        activities.filter((activity: any) => activity.data.registry === contractAddress).length === 0) && (
        <Box my="30px" textAlign="center">
          No activities
        </Box>
      )}
      {activities && activities.filter((activity: any) => activity.data.registry === contractAddress).length > 0 && (
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              borderRadius: "17px",
              background: "#141111",
              [`& .${tableCellClasses.root}`]: {
                color: "rgba(255,255,255,0.75)",
                paddingY: "18px",
                borderBottom: "1px solid #424242",
              },
              [`& tr:last-child .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ color: "red", borderBottom: "1px solid #424242" }}>
              <TableRow>
                <TableCell />
                <TableCell style={{ color: "#828282", fontSize: "16px" }}>Received</TableCell>
                <TableCell style={{ color: "#828282", fontSize: "16px" }}>Deposited</TableCell>
                <TableCell style={{ color: "#828282", fontSize: "16px" }}>Address</TableCell>
                <TableCell style={{ color: "#828282", fontSize: "16px" }}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map((activity: any) =>
                activity.name.includes("Swap") ? (
                  <React.Fragment key={`${activity.txHash} ${activity.data.myNFT}`}>
                    <TableRow>
                      <TableCell style={{ fontSize: "16px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          <Icon component={activityIcons[activity.name]} />
                        </Box>
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Avatar
                            src={
                              retrieveNFTImage(activity.data.registry, activity.data.myNFT) || activity.data.myNFTImage
                            }
                            sx={{ mr: 2, width: 56, height: 56, borderRadius: "10px" }}
                            variant="rounded"
                          />
                          {activity.data.registryName} #{activity.data.myNFT}
                        </Box>
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Avatar
                            src={
                              retrieveNFTImage(activity.data.registry, activity.data.poolNFT) ||
                              activity.data.poolNFTImage
                            }
                            sx={{ mr: 2, width: 56, height: 56, borderRadius: "10px" }}
                            variant="rounded"
                          />
                          {activity.data.registryName} #{activity.data.poolNFT}
                        </Box>
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }}>{formatWalletAddress(activity.data.owner)}</TableCell>
                      <TableCell style={{ fontSize: "16px" }}>
                        {moment(new Date(activity.createdAt.seconds * 1000)).fromNow()}{" "}
                        <Link
                          href={`https://${chainId === 1 ? "" : "rinkeby."}etherscan.io/tx/${activity.data.txHash}`}
                          target="_blank"
                          rel="noopener"
                          style={{ verticalAlign: "text-top" }}
                        >
                          <Icon component={OpenInNewIcon} sx={{ fontSize: "20px", color: "rgba(255,255,255,0.75)" }} />
                        </Link>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ) : (
                  <TableRow key={`${activity.txHash} ${activity.data.myNFT}`}>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                        {activity.name in activityIcons && <Icon component={activityIcons[activity.name]} />}
                        <Box>{activity.name}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {activity.name.includes("Deposit") || activity.name.includes("Withdraw")
                        ? activity.name.includes("Deposit")
                          ? "+"
                          : "-"
                        : ""}{" "}
                      {activity.name.includes("Eth") || activity.name.includes("Set")
                        ? `${activity.data.amount} Ξ`
                        : `${activity.data.nfts.length} NFT(s)`}
                    </TableCell>
                    <TableCell>
                      {activity.name.includes("Withdraw") ? `${activity.data.registryName}` : activity.data.owner}
                    </TableCell>
                    <TableCell>
                      {activity.name.includes("Deposit")
                        ? `${activity.data.registryName}`
                        : activity.name.includes("Create") || activity.name.includes("Set")
                        ? ""
                        : activity.data.owner}
                    </TableCell>
                    <TableCell>{moment(new Date(activity.createdAt.seconds * 1000)).fromNow()} </TableCell>
                    <TableCell>
                      <Link
                        href={`https://rinkeby.etherscan.io/tx/${activity.data.txHash}`}
                        target="_blank"
                        rel="noopener"
                      >
                        <Icon component={OpenInNewIcon} />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
export default PoolActivity;

const retrieveNFTImage = (registryAddress: string, tokenId: number) =>
  COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress]
    ? getNFTFirebaseImage(COLLECTIONS_WITH_FIREBASE_IMAGES[registryAddress], tokenId)
    : null;
