export const formatAddress = (addr: string) => {
  if (addr) {
    return `${addr.slice(0, 6)}...${addr.slice(addr.length - 4, addr.length)}`;
  }
  return "";
};

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

export default { formatAddress };
