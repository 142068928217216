import React, { useImperativeHandle, useEffect, useState, useCallback } from "react";
import Dialog from "components/Dialog";
import { Typography, Button, IconButton, Stack, Box, InputAdornment } from "@mui/material";
import GrayTextInput from "components/GrayTextInput";
import { icon_close } from "assets/svg";

interface Props {
  open: boolean;
  price?: number;
  isTxGoing: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}
const Index = React.forwardRef(({ open, price, isTxGoing, onClose, onConfirm }: Props, ref) => {
  const [buyBackPrice, setBuyBackPrice] = useState(price || 0);

  useImperativeHandle(
    ref,
    () => ({
      getData: () => buyBackPrice,
    }),
    [buyBackPrice]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "720px", mb: "20px" }}>
        <Typography
          sx={{
            alignItems: "center",
            color: "#333333",
            fontWeight: "500",
            fontSize: "30px",
            lineHeight: "35px",
          }}
        >
          Change Buyback Price
        </Typography>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <Box component="img" src={icon_close} />
        </IconButton>
      </Box>
      <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#2F80ED" }}>
        This is the price at which users can sell their NFT to your pool for.
        <br />
        You can change this price at any time, with gas.
      </Typography>
      <Box sx={{ mt: "20px" }}>
        <Stack direction="row" alignItems="center" gap="30px">
          <Box sx={{ width: "280px" }}>
            <Typography sx={{ fontWeight: 400, fontSize: "18px", lineHeight: "21px", textAlign: "center" }}>
              Buyback Price (Ξ)
            </Typography>
            <GrayTextInput
              sx={{ mt: "8px", width: "100%" }}
              value={buyBackPrice}
              type="number"
              onChange={(e: any) => {
                setBuyBackPrice(e.target.value);
              }}
            />
          </Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "700", lineHeight: "21px", mt: "29px" }}>
            Current buyback price &nbsp;
            <Typography component="span" sx={{ color: "#2F80ED" }}>
              {price} Ξ
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ mt: "20px", display: "flex" }}>
        {!isTxGoing ? (
          <Button
            variant="contained"
            sx={{ mr: 0, ml: "auto" }}
            onClick={() => {
              if (onConfirm) onConfirm();
            }}
          >
            Confirm
          </Button>
        ) : (
          <Typography sx={{ color: "#2F80ED", mr: 0, ml: "auto" }}>Processing Transaction...</Typography>
        )}
      </Box>
    </Dialog>
  );
});

export default Index;
