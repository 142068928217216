import Dialog from "components/Dialog";
import { Typography, Button, IconButton, Stack, Box } from "@mui/material";
import NFTCard from "components/NFTCard";
import { icon_tag, icon_close } from "assets/svg";
import { useEffect } from "react";

interface Props {
  open: boolean;
  showSellResult: boolean;
  price: number;
  isTxGoing: boolean;
  nftInfo: {
    registry: string;
    registryName: string;
    tokenId: number;
  };
  onClose: () => void;
  handleSell: () => void;
}

const Index = function ({ open, isTxGoing, price, nftInfo, showSellResult, handleSell, onClose }: Props) {
  useEffect(() => {
    if (showSellResult) {
      setTimeout(() => onClose(), 3000);
    }
  }, [showSellResult]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {!showSellResult ? (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
            >
              Sell to pool for &nbsp;
              <Typography
                component="span"
                sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                {price} Ξ
              </Typography>
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Box component="img" src={icon_close} />
            </IconButton>
          </Box>

          <Typography
            sx={{
              color: "#828282",
              mt: "30px",
              mb: "10px",
              textAlign: "center",
              lineHeight: "24px",
              fontSize: "16px",
            }}
          >
            Sell My NFT
          </Typography>
          <Box sx={{ width: "340px", mx: "auto", display: "flex", justifyContent: "center", mb: "40px" }}>
            <NFTCard
              registryAddress={nftInfo.registry}
              registryName={nftInfo.registryName}
              tokenId={nftInfo.tokenId}
              maxWidth={225}
            />
          </Box>
          {!isTxGoing ? (
            <Button
              variant="contained"
              startIcon={<Box component="img" src={icon_tag} />}
              sx={{ width: "100%" }}
              onClick={handleSell}
            >
              Confirm and Sell Now
            </Button>
          ) : (
            <Typography sx={{ color: "#2F80ED", textAlign: "center" }}>Processing Transaction...</Typography>
          )}
        </Box>
      ) : (
        <Box>
          <IconButton
            sx={{ float: "right" }}
            onClick={() => {
              onClose();
            }}
          >
            <Box component="img" src={icon_close} />
          </IconButton>
          <Box>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
                mb: 2,
              }}
            >
              You received{" "}
              <Typography
                component="span"
                sx={{ color: "#2F80ED", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                {price} Ξ
              </Typography>
            </Typography>
          </Box>
          <Typography sx={{ color: "#2F80ED", textAlign: "center" }}>Your transaction was successful!</Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default Index;
